import { IItemConfig } from '@dabapps/chadmin';
import { FormValueSelector } from '@dabapps/chadmin/dist/edit-create/dynamic-forms/field-types/types';

import { OTHER } from '^/app/unauthenticated/user/questionnaire/const';
import { convertRecordToChadminChoices } from '^/utils/forms';

export function getConfig(
  personalityOptions: Record<string, string>,
  musicBasedActivitiesOptions: Record<string, string>,
  faithOptions: Record<string, string>,
  musicImportanceOptions: Record<string, string>
): IItemConfig {
  const personalityChoices = convertRecordToChadminChoices(personalityOptions);
  const musicBasedActivitiesChoices = convertRecordToChadminChoices(
    musicBasedActivitiesOptions
  );
  const faithChoices = convertRecordToChadminChoices(faithOptions);
  const musicImportanceChoices = convertRecordToChadminChoices(
    musicImportanceOptions
  );

  return {
    personality_type: {
      label: 'What was your or your loved one’s personality as a teenager?',
      type: 'radio',
      fieldGroupClassName: 'bold-label',
      choices: personalityChoices,
      columnProps: {
        xs: 12,
      },
    },
    music_based_activities: {
      label:
        'What kind of music-based activities did you or your loved one do frequently as a teenager? (you can select more than one)',
      type: 'many checkbox',
      fieldGroupClassName: 'bold-label',
      choices: musicBasedActivitiesChoices,
      columnProps: {
        xs: 12,
      },
    },
    music_based_activities_other_freetext: {
      type: 'text',
      columnProps: {
        xs: 12,
      },
      fieldGroupClassName: 'bold-label',
      fieldWrapperClassName: 'margin-left-large',
      placeholder: 'Other music-based activity',
      isDisabled: (selector: FormValueSelector) => {
        const selectedMusicBasedActivities = selector('music_based_activities');
        if (selectedMusicBasedActivities) {
          const isOtherSelected = Boolean(
            selectedMusicBasedActivities.find(
              (selectedOption: string) => selectedOption === OTHER
            )
          );
          return !isOtherSelected;
        }

        return true;
      },
    },
    musical_instrument_freetext: {
      label:
        'If you or your loved one played a musical instrument, what was it?',
      type: 'text',
      placeholder: 'Enter instrument if any',
      columnProps: {
        xs: 12,
      },
    },
    faith: {
      label:
        'Which faith were you or your loved one brought up in? (We ask this question in order to tailor the playlist further, with the most relevant music)',
      type: 'radio',
      fieldGroupClassName: 'bold-label',
      choices: faithChoices,
      columnProps: {
        xs: 12,
      },
    },
    faith_other_freetext: {
      type: 'text',
      columnProps: {
        xs: 12,
      },
      fieldGroupClassName: 'bold-label',
      fieldWrapperClassName: 'margin-left-large',
      placeholder: 'Other faith',
      isDisabled: (selector: FormValueSelector) => {
        const selectedFaith = selector('faith');
        if (selectedFaith) {
          const isOtherSelected = selectedFaith === OTHER;
          return !isOtherSelected;
        }

        return true;
      },
    },
    music_importance: {
      label: 'How important was music to you or your loved one growing up?',
      type: 'text',
      fieldGroupClassName: 'bold-label',
      choices: musicImportanceChoices,
      columnProps: {
        xs: 12,
      },
    },
  };
}
