import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { focusFieldOnMountRef } from '^/utils/forms';

interface FormData {
  email: string;
}

type OwnProps = InjectedFormProps<FormData>;

type Props = OwnProps;

const ForgotPasswordForm: React.FunctionComponent<Props> = props => (
  // eslint-disable-next-line react/jsx-handler-names
  <form onSubmit={props.handleSubmit}>
    <FormGroup block>
      <label htmlFor="email">Email</label>
      <Field
        ref={focusFieldOnMountRef}
        id="email"
        component="input"
        name="email"
        type="email"
      />
    </FormGroup>
    <Button block className="margin-vertical-base primary" type="submit">
      Reset password
    </Button>
  </form>
);

export default reduxForm<FormData>({
  form: 'forgot-password',
  destroyOnUnmount: false,
})(ForgotPasswordForm);
