import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import {
  CreateResearchSessionData,
  CreateUpdateResearchSessionResponseData,
} from '^/app/authenticated/session-management/types';
import { showFormErrorToast } from '^/common/error-handling/utils';
import { ThunkDispatch } from '^/store/types';

export const CREATE_SESSION_AND_REDIRECT_TO_SESSION_LISTING = makeAsyncActionSet(
  'CREATE_SESSION_AND_REDIRECT_TO_SESSION_LISTING'
);
export const createSessionAndRedirectToSessionListing = (
  data: CreateResearchSessionData
) => {
  return (dispatch: ThunkDispatch) => {
    return request<CreateUpdateResearchSessionResponseData>(
      CREATE_SESSION_AND_REDIRECT_TO_SESSION_LISTING,
      '/api/research-sessions/',
      'POST',
      data,
      {
        shouldRethrow: () => true,
      }
    )(dispatch)
      .then(response => {
        if (response) {
          dispatch(push('/research'));
          toast.success('Session created.');
        }
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
};
