import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';
import _ from 'underscore';

import { ThunkDispatch } from '^/store/types';
import { SetMusicalPreferencesData } from './types';
import { GetCurrentPageForPublicSessionData } from '^/app/unauthenticated/user/questionnaire/types';
import { showFormErrorToast } from '^/common/error-handling/utils';

export const FAVOURITE_MUSIC_TYPES_KEY = 'favourite_music_type';
export const FAVOURITE_MUSIC_SUB_TYPES_KEY = 'music_sub_type';

const getFavouriteMusicTypes = (data: SetMusicalPreferencesData) => {
  return _.chain(Object.keys(data))
    .filter(function(key) {
      return (
        key.startsWith(FAVOURITE_MUSIC_TYPES_KEY) &&
        data[key as keyof SetMusicalPreferencesData] === true
      );
    })
    .map(function(key) {
      return key.substring(FAVOURITE_MUSIC_TYPES_KEY.length + 1);
    })
    .value();
};

const getFavouriteMusicSubTypes = (
  data: SetMusicalPreferencesData,
  sub_type_prefix: 'classical'
) => {
  return _.chain(Object.keys(data))
    .filter(function(key) {
      return (
        key.startsWith(
          `${FAVOURITE_MUSIC_SUB_TYPES_KEY}_${sub_type_prefix}_`
        ) && data[key as keyof SetMusicalPreferencesData] === true
      );
    })
    .map(function(key) {
      return key.substring(
        FAVOURITE_MUSIC_SUB_TYPES_KEY.length + sub_type_prefix.length + 2
      );
    })
    .value();
};

export const SET_MUSICAL_PREFERENCES = makeAsyncActionSet(
  'SET_MUSICAL_PREFERENCES'
);
export function setMusicalPreferences(
  sessionId: string,
  data: SetMusicalPreferencesData,
  isResearchQuestionnaire: boolean
) {
  const dataToSend = {
    favourite_music_types: getFavouriteMusicTypes(data),
    favourite_music_sub_types: {
      classical: getFavouriteMusicSubTypes(data, 'classical'),
    },
    favourite_music_sub_types_other_freetext: {
      classical: data.music_sub_type_other_freetext_classical,
    },
    favourite_music_types_other_freetext:
      data.favourite_music_types_other_freetext,
    favourite_artists_and_songs: {
      artist_1: data.artist_1,
      artist_2: data.artist_2,
      artist_3: data.artist_3,
      song_1: data.song_1,
      song_2: data.song_2,
      song_3: data.song_3,
    },
    include_songs_from_countries_of_residence:
      data.include_songs_from_countries_of_residence,
  };

  return (dispatch: ThunkDispatch) => {
    const url = `/api/${
      isResearchQuestionnaire ? 'research-sessions' : 'sessions'
    }/${sessionId}/set-musical-preferences/`;

    return request<GetCurrentPageForPublicSessionData>(
      SET_MUSICAL_PREFERENCES,
      url,
      'POST',
      dataToSend,
      {
        shouldRethrow: () => true,
        metaData: {
          data,
        },
      }
    )(dispatch)
      .then(() => {
        toast.success('Musical preferences updated successfully.');
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
}
