export interface WizardPage {
  name: string;
  title?: string;
  pageContent: JSX.Element;
}

export interface WizardPageContentProps {
  currentPage: number;
}

export enum PublicUserSessionPageName {
  NAME_AND_EMAIL = 'NAME_AND_EMAIL',
  BACKGROUND_INFORMATION = 'BACKGROUND_INFORMATION',
  PERSONALITY_AND_BEHAVIOUR = 'PERSONALITY_AND_BEHAVIOUR',
  MUSICAL_PREFERENCES = 'MUSICAL_PREFERENCES',
  FEEDBACK = 'FEEDBACK',
  GENERATING_PLAYLIST = 'GENERATING_PLAYLIST',
  RESULTS = 'RESULTS',
}

export type QuestionnaireWizardState = {
  [key: string]: string;
};
