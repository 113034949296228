import { AnyAction } from 'redux';

export const CHANGE_CURRENT_WIZARD_PAGE = 'CHANGE_CURRENT_WIZARD_PAGE';
export function changeCurrentWizardPage(
  questionnaireId: string,
  pageName: string
): AnyAction {
  return {
    type: CHANGE_CURRENT_WIZARD_PAGE,
    pageName,
    questionnaireId,
  };
}
