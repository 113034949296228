import {
  AsyncActionSet,
  hasFailed,
  isPending,
  ResponsesReducerState,
  ResponseState,
} from '@dabapps/redux-requests';
import { AxiosResponse } from 'axios';

import { isAxiosResponse } from './types';

export const noop = () => undefined;

export function getResponseState(
  state: ResponsesReducerState,
  actionSet: AsyncActionSet,
  tag?: string
): ResponseState {
  return (state[actionSet.REQUEST] || {})[tag || ''] || {};
}

export function getResponseData(
  state: ResponsesReducerState,
  actionSet: AsyncActionSet,
  tag?: string
): AxiosResponse | null {
  if (!hasFailed(state, actionSet, tag) && !isPending(state, actionSet, tag)) {
    const responseState = getResponseState(state, actionSet, tag);
    if (responseState.data && isAxiosResponse(responseState.data)) {
      return responseState.data as AxiosResponse;
    }
  }
  return null;
}
