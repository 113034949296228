import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Column, Container, Row } from '@dabapps/roe';

type RouteProps = RouteComponentProps<{
  sessionId: string;
}>;

type Props = RouteProps;

export class YoutubePlaylistCreated extends React.PureComponent<Props> {
  public render() {
    return (
      <Container>
        <div className="user-page-title">
          <h2 className="font-color-base">
            Thanks - We've emailed you a link to your playlist!
          </h2>
        </div>
        <p>We've created a playlist on your YouTube account!</p>
        <p>
          We've emailed a link to the playlist to the email address you gave us
          with some instructions on how to access it.
        </p>
        <p>
          We'd appreciate some feedback on how this playlist works for your
          family member/friend.
        </p>
        <p>
          Please keep a note of any observations you have about favourite pieces
          of music and the responses you notice. How often is the music played?
          Is the music helpful at difficult care times, for instance?
        </p>
        <p>
          <strong>Happy listening!</strong>
        </p>
        <Column md={12} className="padding-top-large">
          <Row>
            <Column md={4} className="text-align-center padding-bottom-large">
              <p className="bold font-size-large">
                Share your feedback about the App
              </p>
              <a
                href="https://forms.gle/yi1Kd2H5Pg9CYL9K8"
                target="_blank"
                className="styled-link secondary"
              >
                Start Survey
              </a>
            </Column>
            <Column md={4} className="text-align-center padding-bottom-large">
              <p className="bold font-size-large">Join our Facebook Group</p>
              <a
                href="https://www.facebook.com/groups/209835070885078"
                target="_blank"
                className="styled-link facebook"
              >
                Join Group
              </a>
            </Column>
            <Column md={4} className="text-align-center padding-bottom-large">
              <p className="bold font-size-large">Help us by donating</p>
              <a href="//widgets.justgiving.com/Button/Redirect?p=eyJJZCI6IjNjNTAzMGRiLTY0OGEtNDY2YS1hZTMyLTBhYzYwMjMyZGVlZSIsIkNoYXJpdHlJZCI6Mjc1NDA2OCwiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJKR3dlYnNpdCIsIlR5cGUiOiJEb25hdGUifQ==">
                <img src="//widgets.justgiving.com/Button?p=eyJJZCI6IjNjNTAzMGRiLTY0OGEtNDY2YS1hZTMyLTBhYzYwMjMyZGVlZSIsIkNoYXJpdHlJZCI6Mjc1NDA2OCwiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJKR3dlYnNpdCIsIlR5cGUiOiJEb25hdGUifQ==" />
              </a>
            </Column>
          </Row>
        </Column>
      </Container>
    );
  }
}

export default YoutubePlaylistCreated;
