import React from 'react';
import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { Column, SpacedGroup, Button } from '@dabapps/roe';
import { connect, ResolveThunks } from 'react-redux';
import { isPending, hasFailed, getErrorData } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';

import { noop } from '^/common/utils';
import {
  setPersonalityAndBehaviour,
  SET_PERSONALITY_AND_BEHAVIOUR,
} from './actions';
import { getConfig } from './config';
import {
  selectPersonalityOptions,
  selectMusicBasedActivitiesOptions,
  selectFaithOptions,
  selectMusicImportanceOptions,
} from './selectors';
import { StoreState } from '^/store/types';
import { SetPersonalityAndBehaviourData } from './types';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';
import { changeCurrentWizardPage } from '^/common/components/questionnaire/wizard/actions';
import { RESEARCH_QUESTIONNAIRE_ID } from '^/app/authenticated/questionnaire/const';
import { ResearchQuestionnairePageName } from '^/app/authenticated/questionnaire/types';

interface OwnProps {
  sessionId: string;
  readonly?: boolean;
  isResearchQuestionnaire: boolean;
  initialData?: SetPersonalityAndBehaviourData;
}

interface StateProps {
  personalityOptions: Record<string, string>;
  musicBasedActivitiesOptions: Record<string, string>;
  faithOptions: Record<string, string>;
  musicImportanceOptions: Record<string, string>;
  isLoading: boolean;
  hasFailed: boolean;
  errors?: FormErrors;
}

type DispatchProps = ResolveThunks<{
  setPersonalityAndBehaviour: typeof setPersonalityAndBehaviour;
  changeCurrentWizardPage: typeof changeCurrentWizardPage;
  push: typeof push;
}>;

type Props = StateProps & DispatchProps & OwnProps;

export class PersonalityAndBehaviour extends React.PureComponent<Props> {
  private setPersonalityAndBehaviour = (
    data: SetPersonalityAndBehaviourData
  ) => {
    return this.props.setPersonalityAndBehaviour(
      this.props.sessionId,
      data,
      this.props.isResearchQuestionnaire
    );
  };

  private updatePersonalityAndBehaviour = (
    data: Partial<SetPersonalityAndBehaviourData>
  ) => {
    if (this.props.initialData) {
      const newData = {
        ...this.props.initialData,
        ...data,
      };

      this.setPersonalityAndBehaviour(newData);
    }
  };

  private onClickCancel = () => {
    if (this.props.isResearchQuestionnaire) {
      return this.props.changeCurrentWizardPage(
        RESEARCH_QUESTIONNAIRE_ID,
        ResearchQuestionnairePageName.BACKGROUND_INFORMATION
      );
    }

    return this.props.push('/');
  };

  private onClickNext = () => {
    this.props.changeCurrentWizardPage(
      RESEARCH_QUESTIONNAIRE_ID,
      ResearchQuestionnairePageName.MUSICAL_PREFERENCES
    );
  };

  public render() {
    const config = getConfig(
      this.props.personalityOptions,
      this.props.musicBasedActivitiesOptions,
      this.props.faithOptions,
      this.props.musicImportanceOptions
    );

    return (
      <div>
        <h3>Personality and Music Behaviour (page 2 of 4)</h3>
        <p>
          <strong>
            The following questions refer to the period between 13 and 19 years
            of age.
          </strong>
        </p>
        <hr />
        <Column md={6}>
          <AdminEditCreate
            formName="PERSONALITY_AND_BEHAVIOUR"
            fields={Object.keys(config)}
            itemOptions={config}
            setPendingUploadInForm={noop}
            createItem={this.setPersonalityAndBehaviour}
            patchItem={this.updatePersonalityAndBehaviour}
            saveButtonClassName="secondary margin-vertical-base"
            saveButtonText="Next..."
            itemErrors={this.props.errors}
            item={this.props.initialData}
            itemIsPending={this.props.isLoading}
            readOnly={this.props.readonly}
            additionalFormControls={
              <SpacedGroup>
                {this.props.isResearchQuestionnaire && this.props.readonly && (
                  <Button className="primary" onClick={this.onClickNext}>
                    Next Page
                  </Button>
                )}
                <a
                  className="margin-left-large text-decoration-none uppercase font-weight-normal link-grey"
                  onClick={this.onClickCancel}
                >
                  {this.props.isResearchQuestionnaire
                    ? 'Previous Page'
                    : 'Cancel'}
                </a>
              </SpacedGroup>
            }
          />
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    personalityOptions: selectPersonalityOptions(state),
    musicBasedActivitiesOptions: selectMusicBasedActivitiesOptions(state),
    faithOptions: selectFaithOptions(state),
    musicImportanceOptions: selectMusicImportanceOptions(state),
    isLoading: isPending(state.responses, SET_PERSONALITY_AND_BEHAVIOUR),
    hasFailed: hasFailed(state.responses, SET_PERSONALITY_AND_BEHAVIOUR),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(state.responses, SET_PERSONALITY_AND_BEHAVIOUR)
    ),
  };
}

export default connect(mapStateToProps, {
  setPersonalityAndBehaviour,
  changeCurrentWizardPage: changeCurrentWizardPage,
  push,
})(PersonalityAndBehaviour);
