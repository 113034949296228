import { IItemConfig } from '@dabapps/chadmin';
import { FormValueSelector } from '@dabapps/chadmin/dist/edit-create/dynamic-forms/field-types/types';

import { convertRecordToChadminChoices } from '^/utils/forms';
import { OTHER } from '^/app/unauthenticated/user/questionnaire/const';

export function getConfig(
  discoveryTypes: Record<string, string>,
  relationTypes: Record<string, string>,
  questionnaireExperienceTypes: Record<string, string>
): IItemConfig {
  const discoveryChoices = convertRecordToChadminChoices(discoveryTypes);
  const relationChoices = convertRecordToChadminChoices(relationTypes);
  const questionnaireExperienceChoices = convertRecordToChadminChoices(
    questionnaireExperienceTypes
  );

  return {
    how_did_you_discover: {
      label: 'How did you discover our Playlist Maker?',
      type: 'radio',
      fieldGroupClassName: 'bold-label',
      choices: discoveryChoices,
      columnProps: {
        xs: 12,
      },
    },
    how_did_you_discover_other_freetext: {
      type: 'text',
      columnProps: {
        xs: 12,
      },
      fieldWrapperClassName: 'margin-left-large',
      fieldGroupClassName: 'bold-label',
      isDisabled: (selector: FormValueSelector) => {
        return !(selector('how_did_you_discover') === OTHER);
      },
    },
    person_assisting_relation: {
      label: 'Did anyone help you with filling out this questionnaire?',
      type: 'radio',
      fieldGroupClassName: 'bold-label',
      choices: relationChoices,
      columnProps: {
        xs: 12,
      },
    },
    person_assisting_other_freetext: {
      type: 'text',
      columnProps: {
        xs: 12,
      },
      fieldWrapperClassName: 'margin-left-large',
      fieldGroupClassName: 'bold-label',
      isDisabled: (selector: FormValueSelector) => {
        return !(selector('person_assisting_relation') === OTHER);
      },
    },
    time_taken_to_complete_freetext: {
      label:
        'How long did it take you approximately to fill out the questionnaire?',
      type: 'text',
      fieldGroupClassName: 'bold-label',
      columnProps: {
        xs: 12,
      },
    },
    questionnaire_experience_feedback: {
      label:
        'How did you find the experience of filling this questionnaire out?',
      type: 'radio',
      fieldGroupClassName: 'bold-label',
      choices: questionnaireExperienceChoices,
      columnProps: {
        xs: 12,
      },
    },
    written_feedback_freetext: {
      label:
        "Please provide any comments or issues you had with filling out the questionnaire. We'd love to hear if you have any suggestions on how to improve it.",
      type: 'text',
      fieldGroupClassName: 'bold-label',
      columnProps: {
        xs: 12,
      },
    },
  };
}
