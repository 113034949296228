import React from 'react';
import { Modal, ModalBody, Button } from '@dabapps/roe';
import { connect, ResolveThunks } from 'react-redux';

import { closeModal } from '^/common/modals/actions';
import { SetFeedbackData } from './feedback/types';
import { setFeedbackAndRedirectToSessionOverview } from './feedback/actions';

interface OwnProps {
  sessionId: string;
  data: SetFeedbackData;
  isResearchQuestionnaire: boolean;
}

type DispatchProps = ResolveThunks<{
  closeModal: typeof closeModal;
  setFeedbackAndRedirectToSessionOverview: typeof setFeedbackAndRedirectToSessionOverview;
}>;

type Props = DispatchProps & OwnProps;

export class SubmitQuestionnaireModal extends React.PureComponent<Props> {
  private onClickSubmit = () => {
    this.props.setFeedbackAndRedirectToSessionOverview(
      this.props.sessionId,
      this.props.data
    );
  };

  private onClickNo = () => {
    this.props.closeModal();
  };

  private onClickOutside = () => {
    this.props.closeModal();
  };

  public render() {
    return (
      <Modal onClickOutside={this.onClickOutside}>
        <ModalBody>
          <div className="padding-large text-align-center">
            <p>Are you sure you want to submit the questionnaire?</p>
            <div>
              <Button onClick={this.onClickSubmit} className="primary">
                Yes - Submit
              </Button>
            </div>
            <div className="padding-vertical-base">
              <a onClick={this.onClickNo}>No - continue editing</a>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(null, {
  closeModal,
  setFeedbackAndRedirectToSessionOverview,
})(SubmitQuestionnaireModal);
