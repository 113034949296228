import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {
  combineReducers,
  applyMiddleware,
  createStore,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { responsesReducer } from '@dabapps/redux-requests';
import { reducer as formReducer } from 'redux-form';

import * as authReducers from '^/app/unauthenticated/auth/reducers';
import { AnyThunkActionOrAction } from '^/common/types';
import { StoreState } from '^/store/types';
import { CURRENT_USER } from '^/utils/template';
import { collections } from '^/common/collections';
import { questionnaireWizard } from '^/common/components/questionnaire/wizard/reducers';
import { questionnaireOptions } from '^/app/unauthenticated/user/questionnaire/reducers';
import { researchQuestionnaire } from '^/app/authenticated/questionnaire/reducers';
import { modals } from '^/common/modals/reducers';
import {
  observationFormChanged,
  observationPage,
} from '^/app/authenticated/listening-session/observation/reducers';

export const history = createBrowserHistory();

const rootReducer = combineReducers<StoreState>({
  router: connectRouter(history),
  form: formReducer,
  collections: collections.reducers.collectionsReducer,
  items: collections.reducers.itemsReducer,
  responses: responsesReducer,
  questionnaireOptions,
  questionnaireWizard,
  researchQuestionnaire,
  modals: modals,
  observationFormChanged: observationFormChanged,
  observationPage: observationPage,
  ...authReducers,
});

const middleware: Array<Middleware<{}, StoreState>> = [
  thunk,
  routerMiddleware(history),
  <S>(api: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => (
    action: AnyThunkActionOrAction
  ) =>
    typeof action === 'function'
      ? next(action(api.dispatch, api.getState, undefined))
      : next(action),
];

export const store = createStore(
  rootReducer,
  {
    currentUser: CURRENT_USER,
  },
  composeWithDevTools(applyMiddleware(...middleware))
);
