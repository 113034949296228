import { createSelector } from 'reselect';

import { selectQuestionnaireOptions } from '^/app/unauthenticated/user/questionnaire/selectors';
import { QuestionnaireOptionsState } from '^/app/unauthenticated/user/questionnaire/types';

export const selectRelationTypes = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) =>
    options.person_assisting_relation_options
);

export const selectInvolvementTypes = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) =>
    options.person_assisting_involvement_options
);

export const selectQuestionnaireExperienceTypes = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) =>
    options.questionnaire_experience_feedback_options
);

export const selectDiscoveryTypes = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) => options.how_did_you_discover_options
);
