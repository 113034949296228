import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@dabapps/roe';

import { StoreState } from '^/store/types';
import { WizardPage } from '^/common/components/questionnaire/wizard/types';
import { selectCurrentWizardPage } from '^/common/components/questionnaire/wizard/selectors';

interface OwnProps {
  pages: ReadonlyArray<WizardPage>;
  questionnaireId: string;
}

interface StateProps {
  currentPageName: string;
}

export type Props = StateProps & OwnProps;

export class QuestionnaireWizard extends React.PureComponent<Props> {
  public componentDidUpdate(nextProps: Props) {
    if (nextProps.currentPageName !== this.props.currentPageName) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    const currentPage = this.props.pages.find(
      page => page.name === this.props.currentPageName
    );

    return (
      <Container>
        {currentPage && (
          <>
            {currentPage.title && (
              <>
                <h1>{currentPage.title}</h1>
                <hr />
              </>
            )}
            {currentPage.pageContent}
          </>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state: StoreState, props: OwnProps): StateProps {
  return {
    currentPageName: selectCurrentWizardPage(props.questionnaireId)(state),
  };
}

export default connect(mapStateToProps)(QuestionnaireWizard);
