import React from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';

import { MusicPlayerSong } from '^/common/types';

type Props = {
  playlist: ReadonlyArray<MusicPlayerSong>;
};

type State = {
  currentSongIndex: number;
};

class MusicPlayer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { currentSongIndex: 0 };
  }

  public render() {
    const { currentSongIndex } = this.state;
    const { playlist } = this.props;

    const currentSong = playlist[currentSongIndex];

    return (
      <AudioPlayer
        src={currentSong.preview_url}
        customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
        customControlsSection={[
          <div className="primary sm-padding-right-large bold" key={undefined}>
            {currentSong.artist.toLowerCase()}
          </div>,
          <div key={undefined}>{currentSong.title.toLowerCase()}</div>,
          <div className="sm-padding-horizontal-small" key={undefined}>
            {' '}
            ·{' '}
          </div>,
          RHAP_UI.CURRENT_TIME,
          <div className="sm-padding-horizontal-small" key={undefined}>
            {' '}
            |{' '}
          </div>,
          RHAP_UI.DURATION,
          RHAP_UI.MAIN_CONTROLS,
        ]}
        autoPlayAfterSrcChange
        showSkipControls
        showJumpControls={false}
        onClickPrevious={this.onClickPrevious}
        onClickNext={this.onClickNext}
        onEnded={this.onSongEnd}
      />
    );
  }

  onClickPrevious = () => {
    if (this.isFirstSong()) {
      this.setState(prevState => ({
        currentSongIndex: prevState.currentSongIndex - 1,
      }));
    }
  };

  onClickNext = () => {
    if (this.isLastSong()) {
      this.setState(prevState => ({
        currentSongIndex: prevState.currentSongIndex + 1,
      }));
    }
  };

  onSongEnd = () => {
    if (this.isLastSong()) {
      setTimeout(
        () =>
          this.setState(prevState => ({
            currentSongIndex: prevState.currentSongIndex + 1,
          })),
        3000
      );
    }
  };

  isFirstSong = () => {
    return this.state.currentSongIndex > 0;
  };

  isLastSong = () => {
    return this.state.currentSongIndex <= this.props.playlist.length;
  };
}

export default MusicPlayer;
