import React from 'react';

import PageHeader from '^/common/components/page-header';

const NotFound: React.FunctionComponent = React.memo(() => (
  <>
    <PageHeader>404 - Page not found.</PageHeader>
    <p>Sorry, the page you are looking for does not exist.</p>
  </>
));

export default NotFound;
