import { IItemConfig } from '@dabapps/chadmin';

import {
  convertRecordToChadminChoices,
  generateYearsArray,
} from '^/utils/forms';

export function getConfig(countryOptions: Record<string, string>): IItemConfig {
  const countryChoices = convertRecordToChadminChoices(countryOptions);

  return {
    birth_year: {
      label: 'What year were you or your loved one born?',
      type: 'text',
      choices: generateYearsArray(),
      fieldGroupClassName: 'bold-label',
      columnProps: {
        xs: 12,
      },
    },
    country_of_origin: {
      label: 'Where were you or your loved one born?',
      type: 'text',
      fieldGroupClassName: 'bold-label',
      columnProps: {
        xs: 12,
      },
      placeholder: 'enter town, country',
    },
    countries_of_residence_between_13_and_19: {
      label:
        'Where did you or your loved one live between 13 and 19 years of age?',
      max_length: 255,
      type: 'many',
      choices: countryChoices,
      fieldGroupClassName: 'bold-label',
      columnProps: {
        xs: 12,
      },
    },
    first_language: {
      label: 'What is yours or your loved one’s first language?',
      type: 'text',
      fieldGroupClassName: 'bold-label',
      columnProps: {
        xs: 12,
      },
      placeholder: 'enter language',
    },
  };
}
