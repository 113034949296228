import { AnyAction } from 'redux';

import { LOGOUT } from '^/app/unauthenticated/auth/actions';
import { CurrentUser } from '^/app/unauthenticated/auth/types';

export const currentUser = (
  state: null | CurrentUser = null,
  action: AnyAction
): null | CurrentUser => {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return null;
    default:
      return state;
  }
};
