import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { ThunkDispatch } from '^/store/types';
import { SetBackgroundInformationData } from './types';
import { GetCurrentPageForPublicSessionData } from '^/app/unauthenticated/user/questionnaire/types';
import { showFormErrorToast } from '^/common/error-handling/utils';
import { getResearchSessionQuestionnaireData } from '^/app/authenticated/questionnaire/actions';
import { changeCurrentWizardPage } from '^/common/components/questionnaire/wizard/actions';
import { RESEARCH_QUESTIONNAIRE_ID } from '^/app/authenticated/questionnaire/const';
import { ResearchQuestionnairePageName } from '^/app/authenticated/questionnaire/types';

export const SET_BACKGROUND_INFORMATION = makeAsyncActionSet(
  'SET_BACKGROUND_INFORMATION'
);
export function setBackgroundInformation(
  sessionId: string,
  data: SetBackgroundInformationData,
  isResearchQuestionnaire: boolean
) {
  return (dispatch: ThunkDispatch) => {
    const url = `/api/${
      isResearchQuestionnaire ? 'research-sessions' : 'sessions'
    }/${sessionId}/set-background-information/`;

    return request<GetCurrentPageForPublicSessionData>(
      SET_BACKGROUND_INFORMATION,
      url,
      'POST',
      data,
      {
        shouldRethrow: () => true,
        metaData: {
          data,
        },
      }
    )(dispatch)
      .then(() => {
        toast.success('Background information updated successfully.');
        if (isResearchQuestionnaire) {
          dispatch(
            changeCurrentWizardPage(
              RESEARCH_QUESTIONNAIRE_ID,
              ResearchQuestionnairePageName.PERSONALITY_AND_BEHAVIOUR
            )
          );
          return getResearchSessionQuestionnaireData(sessionId)(dispatch);
        }
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
}
