import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import {
  createActionSet,
  createFileUploadAction,
} from '@dabapps/django-s3-file-upload';

import { ThunkDispatch } from '^/store/types';
import { showFormErrorToast } from '^/common/error-handling/utils';
import { VideoAnalysisFormDataJsonEncodable } from './types';

export const SUBMIT_VIDEO_ANALYSIS_FORM = makeAsyncActionSet(
  'SUBMIT_VIDEO_ANALYSIS_FORM'
);
export const submitVideoAnalysisFormAndRedirectToSessionOverview = (
  researchSessionId: string,
  data: VideoAnalysisFormDataJsonEncodable
) => {
  return (dispatch: ThunkDispatch) => {
    return request<number>(
      SUBMIT_VIDEO_ANALYSIS_FORM,
      `/api/research-sessions/${researchSessionId}/set-video-feedback/`,
      'POST',
      data,
      {
        shouldRethrow: () => true,
      }
    )(dispatch)
      .then(response => {
        if (response) {
          dispatch(push(`/research/${researchSessionId}`));
          toast.success('Video analysis form submitted');
        }
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
};

export const UPLOAD_VIDEO_CSV_FILE = createActionSet('UPLOAD_VIDEO_CSV_FILE');
export const uploadVideoCsvFile = createFileUploadAction(UPLOAD_VIDEO_CSV_FILE);

export const MARK_FILE_UPLOAD_AS_COMPLETE = makeAsyncActionSet(
  'MARK_FILE_UPLOAD_AS_COMPLETE'
);
export function markFileUploadAsComplete(completeUrl: string) {
  return (dispatch: ThunkDispatch) => {
    return request(MARK_FILE_UPLOAD_AS_COMPLETE, completeUrl, 'POST')(dispatch);
  };
}

export function uploadFileAndSubmitVideoAnalysisForm(
  sessionId: string,
  files: readonly File[]
) {
  return (dispatch: ThunkDispatch) => {
    return uploadVideoCsvFile(files)(dispatch)
      .then(uploadData => {
        if (uploadData) {
          const jsonEncodableData = {
            csv_file: uploadData[0].id,
          };
          return submitVideoAnalysisFormAndRedirectToSessionOverview(
            sessionId,
            jsonEncodableData
          )(dispatch);
        }
        toast.error('There was a problem processing the file upload.');
      })
      .catch(() => {
        toast.error('There was a problem processing the file upload.');
      });
  };
}
