import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { ThunkDispatch } from '^/store/types';

export const GET_OBSERVATION_PLAYLIST = makeAsyncActionSet(
  'GET_OBSERVATION_PLAYLIST'
);
export function getObservationPlaylist(researchSessionId: string) {
  return (dispatch: ThunkDispatch) => {
    return request(
      GET_OBSERVATION_PLAYLIST,
      `/api/research-sessions/${researchSessionId}/observation-playlist/`,
      'GET',
      undefined,
      {
        shouldRethrow: () => true,
      }
    )(dispatch).catch(() => {
      toast.error('There was a problem loading the observation playlist.');
    });
  };
}
