import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  researchSessionId: string;
}

const AdminPageTitle: React.FunctionComponent<Props> = React.memo(props => (
  <div className="admin-page-title">
    <Link to={`/research/${props.researchSessionId}`}>
      {'< Back to Session Overview'}
    </Link>
    <h2>{props.children}</h2>
  </div>
));

export default AdminPageTitle;
