import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { ThunkDispatch } from '^/store/types';
import { ObservationData } from './types';

export const END_SESSION_FOR_ALL = makeAsyncActionSet('END_SESSION_FOR_ALL');
export function endSessionForAll(researchSessionId: string) {
  return (dispatch: ThunkDispatch) => {
    return request(
      END_SESSION_FOR_ALL,
      `/api/research-sessions/${researchSessionId}/lock-observations/`,
      'POST',
      undefined,
      {
        shouldRethrow: () => true,
      }
    )(dispatch).catch(() => {
      toast.error('There was a problem recording the observation.');
    });
  };
}

export const GET_PLAYLIST_SONGS = makeAsyncActionSet('GET_PLAYLIST_SONGS');
export function getPlaylistSongs(researchSessionId: string) {
  return (dispatch: ThunkDispatch) => {
    return request(
      GET_PLAYLIST_SONGS,
      `/api/research-sessions/${researchSessionId}/initial-playlist-songs/`,
      'GET',
      undefined,
      {
        shouldRethrow: () => true,
      }
    )(dispatch);
  };
}

export const CREATE_OBSERVATION = makeAsyncActionSet('CREATE_OBSERVATION');
export function createObservation(
  sessionId: string,
  data: Partial<ObservationData>
) {
  return (dispatch: ThunkDispatch) => {
    return request(
      CREATE_OBSERVATION,
      `/api/research-sessions/${sessionId}/create-observation/`,
      'POST',
      data,
      {
        shouldRethrow: () => true,
      }
    )(dispatch);
  };
}

export const GET_OBSERVATION = makeAsyncActionSet('GET_OBSERVATION');
export function getObservation(
  sessionId: string,
  songSlug: string,
  observationType: string
) {
  return (dispatch: ThunkDispatch) => {
    return request(
      GET_OBSERVATION,
      `/api/research-sessions/${sessionId}/observation/${songSlug}/${observationType}/`,
      'GET',
      undefined
    )(dispatch);
  };
}

export const UPDATE_OBSERVATION = makeAsyncActionSet('UPDATE_OBSERVATION');
export function updateObservation(
  sessionId: string,
  songSlug: string,
  observationType: string,
  data: Partial<ObservationData>
) {
  return (dispatch: ThunkDispatch) => {
    return request(
      UPDATE_OBSERVATION,
      `/api/research-sessions/${sessionId}/observation/${songSlug}/${observationType}/`,
      'PATCH',
      data,
      {
        shouldRethrow: () => true,
      }
    )(dispatch);
  };
}

export const GET_IS_OBSERVATION_LOCKED = makeAsyncActionSet(
  'GET_IS_OBSERVATION_LOCKED'
);
export function getIsObservationLocked(sessionId: string) {
  return (dispatch: ThunkDispatch) => {
    return request(
      GET_IS_OBSERVATION_LOCKED,
      `/api/research-sessions/${sessionId}/is-observation-locked/`,
      'GET'
    )(dispatch);
  };
}

export const NEXT_PAGE = 'NEXT_PAGE';
export function nextPage() {
  return {
    type: NEXT_PAGE,
  };
}

export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
export function previousPage() {
  return {
    type: PREVIOUS_PAGE,
  };
}
