import { TColumns } from '@dabapps/chadmin';
import React from 'react';
import { Link } from 'react-router-dom';

import { ResearchSession } from '^/app/authenticated/session-listing/types';
import StatusIndicator from '^/common/components/status-indicator';

export const sessionListingConfig: TColumns<ResearchSession> = [
  {
    name: 'resident_reference',
    display_name: 'Resident',
    type: 'custom',
    customItemHandler: session => {
      return (
        <Link
          to={`/research/${session.id}`}
          className="primary text-decoration-none"
        >
          {session.resident_reference}
        </Link>
      );
    },
    sortable: true,
  },
  {
    name: 'session_date',
    display_name: 'Session Date',
    type: 'text',
    sortable: true,
  },
  {
    name: 'location',
    display_name: 'Location',
    type: 'text',
    sortable: true,
  },
  {
    name: 'status',
    display_name: 'Status',
    type: 'custom',
    customItemHandler: session => {
      return <StatusIndicator isComplete={session.is_complete} />;
    },
  },
];
