import { request, makeAsyncActionSet } from '@dabapps/redux-requests';

import { ThunkDispatch } from '^/store/types';

export const GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA = makeAsyncActionSet(
  'GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA'
);
export function getResearchSessionQuestionnaireData(sessionId: string) {
  return (dispatch: ThunkDispatch) => {
    return request(
      GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA,
      `/api/research-sessions/${sessionId}/get-questionnaire-data/`,
      'GET',
      undefined,
      {
        shouldRethrow: () => true,
      }
    )(dispatch);
  };
}
