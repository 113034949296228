import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  resetPassword,
  RESET_PASSWORD,
} from '^/app/unauthenticated/auth/actions';
import ResetPasswordForm from '^/app/unauthenticated/auth/components/reset-password/form';
import AuthWrapper from '^/app/unauthenticated/auth/components/wrapper';
import { ResetPasswordData } from '^/app/unauthenticated/auth/types';
import PageHeader from '^/common/components/page-header';
import { getResponseErrors } from '^/common/error-handling/utils';
import { ResponseErrors } from '^/common/error-handling/types';
import { StoreState } from '^/store/types';

export interface RouteParamProps {
  uid: string;
  token: string;
}

interface StateProps {
  isLoading?: boolean;
  errors?: ResponseErrors;
}

type DispatchProps = ResolveThunks<{
  onSubmitResetPasswordForm: typeof resetPassword;
}>;

type Props = StateProps & DispatchProps & RouteComponentProps<RouteParamProps>;

export class ResetPassword extends React.PureComponent<Props> {
  public render() {
    return (
      <AuthWrapper>
        <PageHeader>Reset password</PageHeader>
        <ResetPasswordForm
          onSubmit={this.onFormSubmit}
          errors={this.props.errors}
          isLoading={this.props.isLoading}
        />
      </AuthWrapper>
    );
  }

  private onFormSubmit = (formData: ResetPasswordData) => {
    const {
      match: {
        params: { uid, token },
      },
    } = this.props;
    const data = {
      ...formData,
      uid,
      token,
    };
    this.props.onSubmitResetPasswordForm(data);
  };
}

const mapStateToProps = ({ responses }: StoreState): StateProps => {
  return {
    isLoading: anyPending(responses, [RESET_PASSWORD]),
    errors: getResponseErrors(responses, RESET_PASSWORD),
  };
};

export default connect(mapStateToProps, {
  onSubmitResetPasswordForm: resetPassword,
})(ResetPassword);
