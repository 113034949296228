import { createSelector } from 'reselect';

import { selectQuestionnaireOptions } from '^/app/unauthenticated/user/questionnaire/selectors';
import { QuestionnaireOptionsState } from '^/app/unauthenticated/user/questionnaire/types';

export const selectMusicTypes = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) => options.music_types_options
);

export const selectMusicSubTypes = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) => options.music_type_sub_genre_options
);
