import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { anyPending, getErrorData } from '@dabapps/redux-requests';
import { Container, Column } from '@dabapps/roe';
import React from 'react';
import { ResolveThunks, connect } from 'react-redux';

import {
  createSessionAndRedirectToSessionListing,
  CREATE_SESSION_AND_REDIRECT_TO_SESSION_LISTING,
} from '^/app/authenticated/session-management/add-session/actions';
import { addEditSessionFormConfig } from '^/app/authenticated/session-management/form-config';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';
import { noop } from '^/common/utils';
import PageHeader from '^/common/components/page-header';
import { StoreState } from '^/store/types';

interface StateProps {
  isLoading: boolean;
  errors: FormErrors | undefined;
}

type Props = StateProps &
  ResolveThunks<{
    createSessionAndRedirectToSessionListing: typeof createSessionAndRedirectToSessionListing;
  }>;

export const AddSession: React.FunctionComponent<Props> = React.memo(props => (
  <Container>
    <PageHeader>Add Session</PageHeader>
    <Column md={6}>
      <AdminEditCreate
        fields={Object.keys(addEditSessionFormConfig)}
        formName="ADD_SESSION"
        itemOptions={addEditSessionFormConfig}
        setPendingUploadInForm={noop}
        saveButtonText="Save session"
        saveButtonClassName="primary margin-vertical-base"
        additionalFormControls={
          <a
            href="/research"
            className="text-decoration-none primary padding-large link-grey"
          >
            Cancel
          </a>
        }
        itemErrors={props.errors}
        loading={props.isLoading}
        createItem={props.createSessionAndRedirectToSessionListing}
      />
    </Column>
  </Container>
));

const mapStateToProps = ({ responses }: StoreState): StateProps => {
  return {
    isLoading: anyPending(responses, [
      CREATE_SESSION_AND_REDIRECT_TO_SESSION_LISTING,
    ]),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(responses, CREATE_SESSION_AND_REDIRECT_TO_SESSION_LISTING)
    ),
  };
};

export default connect(mapStateToProps, {
  createSessionAndRedirectToSessionListing,
})(AddSession);
