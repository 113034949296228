import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';

import {
  CreatePublicSessionData,
  CreatePublicSessionResponseData,
} from '^/app/unauthenticated/user/questionnaire/create-session/types';
import { ThunkDispatch } from '^/store/types';

export const CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION = makeAsyncActionSet(
  'CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION'
);
export const createPublicSessionAndRedirectToFirstSection = (
  data: CreatePublicSessionData
) => {
  return (dispatch: ThunkDispatch) => {
    return request<CreatePublicSessionResponseData>(
      CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION,
      '/api/sessions/create-public-session/',
      'POST',
      data,
      {
        shouldRethrow: () => true,
      }
    )(dispatch).then(response => {
      if (response) {
        dispatch(push(`/session/${response.data.session_id}/`));
      }
    });
  };
};
