import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { ThunkDispatch } from '^/store/types';
import { showFormErrorToast } from '^/common/error-handling/utils';
import { PersonalDetailsData } from './types';

export const UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS = makeAsyncActionSet(
  'UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS'
);
export function updatePublicSessionPersonalDetails(
  sessionId: string,
  data: PersonalDetailsData
) {
  return request(
    UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS,
    `/api/sessions/${sessionId}/set-personal-details/`,
    'PUT',
    data,
    { metaData: { sessionId }, shouldRethrow: () => true }
  );
}

export function updatePublicSessionPersonalDetailsAndShowToast(
  sessionId: string,
  data: PersonalDetailsData
) {
  return (dispatch: ThunkDispatch) =>
    updatePublicSessionPersonalDetails(
      sessionId,
      data
    )(dispatch)
      .then(() => {
        toast.success('Details updated.');
      })
      .catch(() => {
        showFormErrorToast();
      });
}
