import { request, makeAsyncActionSet } from '@dabapps/redux-requests';

import { ThunkDispatch } from '^/store/types';
import { GetCurrentPageForPublicSessionData } from '^/app/unauthenticated/user/questionnaire/types';

export const GET_CURRENT_PAGE_FOR_PUBLIC_SESSION = makeAsyncActionSet(
  'GET_CURRENT_PAGE_FOR_PUBLIC_SESSION'
);
export const getCurrentPageForPublicSession = (sessionId: string) => {
  return (dispatch: ThunkDispatch) => {
    return request<GetCurrentPageForPublicSessionData>(
      GET_CURRENT_PAGE_FOR_PUBLIC_SESSION,
      `/api/sessions/get-current-page-for-public-session/${sessionId}/`,
      'GET',
      undefined,
      {
        shouldRethrow: () => true,
      }
    )(dispatch);
  };
};
