import { AppRoot, Container, Nav, NavBar, NavItem } from '@dabapps/roe';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect, ConnectedProps, ResolveThunks } from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import { logout } from '^/app/unauthenticated/auth/actions';
import ForgotPassword from '^/app/unauthenticated/auth/components/forgot-password';
import ForgotPasswordEmailSent from '^/app/unauthenticated/auth/components/forgot-password/email-sent';
import Login from '^/app/unauthenticated/auth/components/login';
import ResetPassword from '^/app/unauthenticated/auth/components/reset-password';
import NotFound from '^/common/components/not-found';
import { StoreState } from '^/store/types';
import UpdateSession from '^/app/authenticated/session-management/update-session';
import AddSession from '^/app/authenticated/session-management/add-session';
import SessionListing from '^/app/authenticated/session-listing';
import ResearchQuestionnaire from '^/app/authenticated/questionnaire';
import SessionOverview from '^/app/authenticated/session-overview';
import ResidentConsentForm from '^/app/authenticated/resident-consent-form';
import ConsulteeConsentForm from '^/app/authenticated/consultee-consent-form';
import ListeningSession, {
  ListeningSessionProps,
} from '^/app/authenticated/listening-session';
import VideoAnalysisForm from '^/app/authenticated/video-analysis-form';
import ObservationPlaylist from '^/app/authenticated/observation-playlist';
import { collections } from '^/common/collections';
import { ObservationType } from './listening-session/observation/types';

const {
  actions: { clearItem },
} = collections;

type RouteProps = RouteComponentProps<{}>;

type DispatchProps = ResolveThunks<{
  clearItem: typeof clearItem;
}>;

const mapStateToProps = ({ currentUser }: StoreState) => ({
  currentUser,
});

const mapDispatchToProps = { onClickLogout: logout, clearItem };

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = RouteProps & DispatchProps & ConnectedProps<typeof connector>;

function getListeningSessionComponent(observationType: ObservationType) {
  return (props: ListeningSessionProps) => (
    <ListeningSession {...props} observationType={observationType} />
  );
}

const ResearchApp: React.FunctionComponent<Props> = React.memo(props => (
  <AppRoot>
    <Helmet>
      <title>Music for my Mind</title>
    </Helmet>
    <NavBar>
      <Container>
        <div className="admin-app-header">
          <Link to="/research" className="logo text-decoration-none">
            <img src="/static/images/brain-cloud.svg" /> Sessions
          </Link>

          <Nav className="float-right">
            {props.currentUser && (
              <NavItem>
                <a>{props.currentUser?.email}</a>
              </NavItem>
            )}
            <NavItem>
              {props.currentUser ? (
                <a onClick={props.onClickLogout}>logout</a>
              ) : (
                <Link to={`${props.match.path}/login`}>login</Link>
              )}
            </NavItem>
          </Nav>
        </div>
      </Container>
    </NavBar>
    <Container>
      {props.currentUser ? (
        <Switch>
          <Redirect exact from={`${props.match.path}/login`} to="/research" />
          <Redirect
            exact
            from={`${props.match.path}/forgot-password`}
            to="/research"
          />
          <Redirect
            exact
            from={`${props.match.path}/forgot-password-email-sent`}
            to="/research"
          />
          <Redirect
            exact
            from={`${props.match.path}/reset-password`}
            to="/research"
          />
          <Route
            exact
            path={`${props.match.path}/add-session`}
            component={AddSession}
          />
          <Route
            exact
            path={`${props.match.path}/update-session/:uid`}
            component={UpdateSession}
            onLeave={props.clearItem('research-sessions')}
          />
          <Route
            exact
            path={`${props.match.path}/:uid`}
            component={SessionOverview}
          />
          <Route
            exact
            path={`${props.match.path}/:sessionId/musical-preferences`}
            component={ResearchQuestionnaire}
          />
          <Route
            exact
            path={`${props.match.path}/:uid/resident-consent-form`}
            component={ResidentConsentForm}
          />
          <Route
            exact
            path={`${props.match.path}/:uid/consultee-consent-form`}
            component={ConsulteeConsentForm}
          />
          <Route
            exact
            path={`${props.match.path}/:uid/video-analysis-form`}
            component={VideoAnalysisForm}
          />
          <Route exact path={props.match.path} component={SessionListing} />
          <Route
            exact
            path={`${props.match.path}/:uid/run-session/`}
            component={getListeningSessionComponent('MASTER')}
          />
          <Route
            exact
            path={`${props.match.path}/:uid/observe-session/`}
            component={getListeningSessionComponent('SUB')}
          />
          <Route
            exact
            path={`${props.match.path}/:uid/playlist/`}
            component={ObservationPlaylist}
          />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Redirect exact path={props.match.path} to="/research/login" />
          <Route exact path={`${props.match.path}/login`} component={Login} />
          <Route
            exact
            path={`${props.match.path}/forgot-password`}
            component={ForgotPassword}
          />
          <Route
            exact
            path={`${props.match.path}/forgot-password-email-sent`}
            component={ForgotPasswordEmailSent}
          />
          <Route
            exact
            path={`${props.match.path}/reset-password/:uid/:token/`}
            component={ResetPassword}
          />
          <Route component={NotFound} />
        </Switch>
      )}
    </Container>
  </AppRoot>
));

export default withRouter(connector(ResearchApp));
