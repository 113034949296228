import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import {
  UpdateResearchSessionData,
  CreateUpdateResearchSessionResponseData,
} from '^/app/authenticated/session-management/types';
import { showFormErrorToast } from '^/common/error-handling/utils';
import { ThunkDispatch } from '^/store/types';

export const EDIT_SESSION_AND_REDIRECT_TO_SESSION_LISTING = makeAsyncActionSet(
  'EDIT_SESSION_AND_REDIRECT_TO_SESSION_LISTING'
);
export const editSessionAndRedirectToSessionListing = (
  researchSessionId: string,
  data: Partial<UpdateResearchSessionData>
) => {
  return (dispatch: ThunkDispatch) => {
    return request<CreateUpdateResearchSessionResponseData>(
      EDIT_SESSION_AND_REDIRECT_TO_SESSION_LISTING,
      `/api/research-sessions/${researchSessionId}/`,
      'PATCH',
      data,
      {
        shouldRethrow: () => true,
      }
    )(dispatch)
      .then(response => {
        if (response) {
          dispatch(push('/research'));
          toast.success('Session updated.');
        }
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
};
