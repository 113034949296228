import { SimpleRecord } from '@dabapps/simple-records';

export type ResearchSessionOverviewData = {
  id: string;
  session_date?: string;
  birth_year?: string;
  resident_reference: string;
  location: string;
  is_resident_consent_form_completed: boolean;
  is_consultee_consent_form_completed: boolean;
  is_music_questionnaire_completed: boolean;
  is_listening_session_completed: boolean;
  is_video_feedback_uploaded: boolean;
  is_initial_playlist_complete: boolean;
  is_final_playlist_and_results_complete: boolean;
  has_locked_observations: boolean;
};

export const ResearchSessionOverviewData = SimpleRecord<
  ResearchSessionOverviewData
>({
  id: '',
  resident_reference: '',
  location: '',
  is_resident_consent_form_completed: false,
  is_consultee_consent_form_completed: false,
  is_music_questionnaire_completed: false,
  is_listening_session_completed: false,
  is_video_feedback_uploaded: false,
  is_initial_playlist_complete: false,
  is_final_playlist_and_results_complete: false,
  has_locked_observations: false,
});
