import React from 'react';
import { Badge } from '@dabapps/roe';

interface Props {
  isComplete: boolean;
  className?: string;
}

export const StatusIndicator: React.FunctionComponent<Props> = React.memo(
  props => (
    <Badge
      className={`display-inline-block font-color-base uppercase status-indicator ${
        props.isComplete ? 'success' : 'warning'
      } ${props.className ? props.className : ''}`}
    >
      {props.isComplete ? 'Complete' : 'Incomplete'}
    </Badge>
  )
);

export default StatusIndicator;
