import ReactDOM from 'react-dom';
import { Field } from 'redux-form';
import moment from 'moment';
import _ from 'underscore';

export function convertRecordToChadminChoices(record: Record<string, string>) {
  return _.pairs(record).map(pair => ({
    display_name: pair[1],
    value: pair[0],
  }));
}

export const generateYearsArray = () => {
  const currentYear = moment().year();
  const startDate = moment('1910-01-01');
  const endDate = moment();
  const difference = endDate.diff(startDate, 'years') + 1;
  const yearsArray = _.times(difference, yearsAgo => currentYear - yearsAgo);

  return yearsArray.map(year => ({
    display_name: year.toString(),
    value: year.toString(),
  }));
};

export const focusFieldOnMountRef = (instance: Field | null) => {
  if (instance) {
    const element = ReactDOM.findDOMNode(instance);

    if (element instanceof HTMLElement) {
      element.focus();
    }
  }
};
