import { SimpleRecord } from '@dabapps/simple-records';

export type ConsulteeConsentFormData = {
  id: string;
  resident_name: string;
  friend_or_relative_name: string;
  consultee_name: string;
  relationship_to_participant: string;
  name_of_researcher: string;
  consultee_has_been_consulted_about_residents_participation: boolean;
  consultee_has_checked_resident_has_advanced_directive: boolean;
  consultee_has_confirmed_no_expressed_wishes_not_to_take_part: boolean;
  consultee_has_considered_residents_wishes: boolean;
  consultee_considers_resident_to_have_no_objection_taking_part: boolean;
  consultee_has_given_permission_for_filming: boolean;
  consultee_has_given_permission_for_data_to_be_retained: boolean;
  consultee_has_agreed_to_take_part_in_study: boolean;
  friend_or_relative_has_confirmed_information_sheet: boolean;
  friend_or_relative_has_confirmed_right_to_withdrawal: boolean;
  friend_or_relative_has_given_permission_for_filming: boolean;
  friend_or_relative_has_given_permission_for_data_to_be_retained: boolean;
  friend_or_relative_has_agreed_to_take_part_in_study: boolean;
};

export const ConsulteeConsentFormData = SimpleRecord<ConsulteeConsentFormData>({
  id: '',
  resident_name: '',
  friend_or_relative_name: '',
  consultee_name: '',
  relationship_to_participant: '',
  name_of_researcher: '',
  consultee_has_been_consulted_about_residents_participation: false,
  consultee_has_checked_resident_has_advanced_directive: false,
  consultee_has_confirmed_no_expressed_wishes_not_to_take_part: false,
  consultee_has_considered_residents_wishes: false,
  consultee_considers_resident_to_have_no_objection_taking_part: false,
  consultee_has_given_permission_for_filming: false,
  consultee_has_given_permission_for_data_to_be_retained: false,
  consultee_has_agreed_to_take_part_in_study: false,
  friend_or_relative_has_confirmed_information_sheet: false,
  friend_or_relative_has_confirmed_right_to_withdrawal: false,
  friend_or_relative_has_given_permission_for_filming: false,
  friend_or_relative_has_given_permission_for_data_to_be_retained: false,
  friend_or_relative_has_agreed_to_take_part_in_study: false,
});

export type ConsulteeConsentFormResponseData = {
  session_id: string;
};
