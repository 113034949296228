import * as yup from 'yup';

import { CurrentUser } from '^/app/unauthenticated/auth/types';

const getTemplateJson = <T>(elementId: string, schema: yup.Schema<T>) => {
  const element = document.getElementById(elementId);

  if (!element) {
    throw new Error(`Could not find JSON script with ID: ${elementId}`);
  }

  const json: T = JSON.parse(element.textContent || '');

  return schema.validateSync(json);
};

const CURRENT_USER_SCHEMA = yup
  .object()
  .nullable()
  .shape<CurrentUser>({
    email: yup.string(),
    name: yup.string(),
  });

export const CURRENT_USER = getTemplateJson(
  'current_user',
  CURRENT_USER_SCHEMA
);

export type FeatureFlags = Record<string, string>;

const FEATURE_FLAGS_SCHEMA = yup.object().shape<FeatureFlags>({});

export const FEATURE_FLAGS = getTemplateJson(
  'feature_flags',
  FEATURE_FLAGS_SCHEMA
);
