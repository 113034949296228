import { Container, Column } from '@dabapps/roe';
import { isPending, getErrorData, hasFailed } from '@dabapps/redux-requests';
import { getItemByName, GET_ITEM } from '@dabapps/redux-api-collections';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ResearchSessionOverviewData } from '^/app/authenticated/session-overview/types';
import { collections } from '^/common/collections';
import { StoreState } from '^/store/types';
import AdminPageTitle from '^/common/components/admin-page-title';
import Loading from '^/common/components/loading';
import { getResponseErrorsFromAxiosError } from '^/common/error-handling/utils';
import {
  uploadFileAndSubmitVideoAnalysisForm,
  submitVideoAnalysisFormAndRedirectToSessionOverview,
  SUBMIT_VIDEO_ANALYSIS_FORM,
} from './actions';
import { VideoAnalysisFormData } from './types';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import { ResponseErrors } from '^/common/error-handling/types';
import UploadForm from './upload-form';

const {
  actions: { getItem },
} = collections;

export type RouteParamProps = RouteComponentProps<{
  uid: string;
}>;

type DispatchProps = ResolveThunks<{
  getItem: typeof getItem;
  uploadFileAndSubmitVideoAnalysisForm: typeof uploadFileAndSubmitVideoAnalysisForm;
}>;

type StateProps = {
  sessionData: ResearchSessionOverviewData | undefined;
  isLoading: boolean;
  errors: ResponseErrors | undefined;
  hasVideoAnalysisCsvUploadFailed: boolean;
};

export type Props = RouteParamProps &
  DispatchProps &
  StateProps &
  ResolveThunks<{
    submitVideoAnalysisFormAndRedirectToSessionOverview: typeof submitVideoAnalysisFormAndRedirectToSessionOverview;
  }>;

export class VideoAnalysisForm extends React.PureComponent<Props> {
  public componentDidMount() {
    const researchSessionUid = this.props.match.params.uid;
    this.props.getItem('research-sessions/overview', researchSessionUid);
  }

  public render() {
    const { sessionData, isLoading } = this.props;

    return (
      <Container>
        {isLoading || !sessionData ? (
          <Loading />
        ) : (
          <>
            <AdminPageTitle researchSessionId={sessionData.id}>
              Video analysis form for {sessionData.resident_reference} -{' '}
              {sessionData.session_date}
            </AdminPageTitle>
            <Column xs={12} sm={6}>
              {this.props.hasVideoAnalysisCsvUploadFailed && (
                <FormErrorRenderer
                  formErrorsKey="non_field_errors"
                  formErrors={this.props.errors}
                />
              )}
              <UploadForm sessionId={sessionData.id} onSubmit={this.onSubmit} />
            </Column>
          </>
        )}
      </Container>
    );
  }

  private onSubmit = (formData: VideoAnalysisFormData) => {
    if (this.props.sessionData?.id) {
      this.props.uploadFileAndSubmitVideoAnalysisForm(
        this.props.sessionData.id,
        Array.from(formData.csv_file)
      );
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    sessionData: getItemByName(state.items, 'research-sessions/overview') as
      | ResearchSessionOverviewData
      | undefined,
    isLoading:
      isPending(state.responses, GET_ITEM, 'research-sessions/overview') ||
      isPending(state.responses, SUBMIT_VIDEO_ANALYSIS_FORM),
    hasVideoAnalysisCsvUploadFailed: hasFailed(
      state.responses,
      SUBMIT_VIDEO_ANALYSIS_FORM
    ),
    errors: getResponseErrorsFromAxiosError(
      getErrorData(state.responses, SUBMIT_VIDEO_ANALYSIS_FORM)
    ),
  };
};

export default connect(mapStateToProps, {
  getItem,
  submitVideoAnalysisFormAndRedirectToSessionOverview,
  uploadFileAndSubmitVideoAnalysisForm,
})(VideoAnalysisForm);
