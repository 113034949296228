import { createSelector } from 'reselect';

import { QuestionnaireOptionsState } from '^/app/unauthenticated/user/questionnaire/types';
import { selectQuestionnaireOptions } from '^/app/unauthenticated/user/questionnaire/selectors';

export const selectPersonalityOptions = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) => options.personality_types
);

export const selectMusicBasedActivitiesOptions = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) => options.music_based_activities
);

export const selectFaithOptions = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) => options.faith_options
);

export const selectMusicImportanceOptions = createSelector(
  selectQuestionnaireOptions,
  (options: QuestionnaireOptionsState) => options.music_importance_options
);
