import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ResponseErrors } from '^/common/error-handling/types';
import { focusFieldOnMountRef } from '^/utils/forms';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';

interface FormData {
  username: string;
  password: string;
}

interface ExternalProps {
  isLoading?: boolean;
  errors?: ResponseErrors;
}

type OwnProps = InjectedFormProps<FormData, ExternalProps>;

type Props = OwnProps & ExternalProps;

const LoginForm: React.FunctionComponent<Props> = props => (
  // eslint-disable-next-line react/jsx-handler-names
  <form onSubmit={props.handleSubmit}>
    <FormGroup block>
      <label htmlFor="email" className="bold">
        Email address
      </label>
      <Field
        ref={focusFieldOnMountRef}
        id="email"
        component="input"
        name="email"
        type="email"
        placeholder="enter your email"
        disabled={props.isLoading}
      />
      <FormErrorRenderer formErrors={props.errors} formErrorsKey="email" />
    </FormGroup>
    <FormGroup block>
      <label htmlFor="password" className="bold">
        Password
      </label>
      <Field
        id="password"
        component="input"
        name="password"
        type="password"
        placeholder="enter your password"
        disabled={props.isLoading}
      />
      <FormErrorRenderer formErrors={props.errors} formErrorsKey="password" />
    </FormGroup>
    <FormErrorRenderer
      formErrors={props.errors}
      formErrorsKey="non_field_errors"
    />
    <Button block className="margin-vertical-base primary" type="submit">
      Login
    </Button>
    <p className="text-align-center">
      <Link to="/research/forgot-password" className="primary">
        Forgot password
      </Link>
    </p>
  </form>
);

export default reduxForm<FormData, ExternalProps>({
  form: 'login',
  destroyOnUnmount: false,
})(LoginForm);
