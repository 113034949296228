import { IItemConfig } from '@dabapps/chadmin';

import { DESKTOP_WIDTH } from './constants';

const config: IItemConfig = {
  full_name: {
    type: 'text',
    label: "What's your full name?",
    placeholder: 'enter first and last name',
    columnProps: { xs: 12, md: DESKTOP_WIDTH },
  },
  email: {
    type: 'text',
    label: 'Enter your email address',
    placeholder: 'enter email address',
    columnProps: { xs: 12, md: DESKTOP_WIDTH },
  },
  phone: {
    type: 'text',
    label: 'Enter your phone number',
    placeholder: 'optional',
    columnProps: { xs: 12, md: DESKTOP_WIDTH },
  },
};
export default config;
