import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { showFormErrorToast } from '^/common/error-handling/utils';
import { ThunkDispatch } from '^/store/types';

export const UPDATE_PLAYLIST_AND_RELOAD_PAGE = makeAsyncActionSet(
  'UPDATE_PLAYLIST_AND_RELOAD_PAGE'
);
export const updatePlaylistAndReloadPage = (
  sessionId: string,
  song_to_remove: string
) => {
  return (dispatch: ThunkDispatch) => {
    return request<number>(
      UPDATE_PLAYLIST_AND_RELOAD_PAGE,
      `/api/sessions/update-playlist/${sessionId}/`,
      'PUT',
      { song_to_remove },
      {
        shouldRethrow: () => true,
      }
    )(dispatch)
      .then(response => {
        if (response) {
          dispatch(push(`/session/${sessionId}`));
          toast.success('Playlist updated.');
        }
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
};

export const UPDATE_PLAYLIST_NAME = makeAsyncActionSet('UPDATE_PLAYLIST_NAME');
export const updatePlaylistName = (
  sessionId: string,
  playlist_name: string
) => {
  return (dispatch: ThunkDispatch) => {
    return request<number>(
      UPDATE_PLAYLIST_AND_RELOAD_PAGE,
      `/api/sessions/update-playlist-name/${sessionId}/`,
      'PUT',
      { playlist_name },
      {
        shouldRethrow: () => true,
      }
    )(dispatch)
      .then(response => {
        if (response) {
          toast.success('Playlist name updated.');
        }
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
};
