import React from 'react';

export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = (element: React.ReactElement) => ({
  type: OPEN_MODAL,
  payload: element,
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const closeAllModals = () => ({
  type: CLOSE_ALL_MODALS,
});
