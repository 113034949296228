import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { StoreState } from '^/store/types';
import {
  LoginData,
  LoginResponseData,
  ForgotPasswordData,
  ResetPasswordData,
} from './types';

export const LOGOUT = makeAsyncActionSet('LOGOUT');
export const logout = () => {
  return request(LOGOUT, '/api/auth/logout/', 'POST');
};

export const LOGIN = makeAsyncActionSet('LOGIN');
export const login = (data: LoginData) => (
  dispatch: ThunkDispatch<StoreState, void, AnyAction>
) => {
  return request<LoginResponseData>(
    LOGIN,
    '/api/auth/login/',
    'POST',
    data
  )(dispatch).then(() => {
    window.location.replace('/research');
  });
};

const FORGOT_PASSWORD = makeAsyncActionSet('FORGOT_PASSWORD');
export const forgotPassword = (data: ForgotPasswordData) => (
  dispatch: ThunkDispatch<StoreState, void, AnyAction>
) => {
  return request(
    FORGOT_PASSWORD,
    '/api/auth/password/reset/',
    'POST',
    data
  )(dispatch).then(() => {
    dispatch(push('/research/forgot-password-email-sent'));
  });
};

export const RESET_PASSWORD = makeAsyncActionSet('RESET_PASSWORD');
export const resetPassword = (data: ResetPasswordData) => {
  return request(
    RESET_PASSWORD,
    '/api/auth/password/reset/confirm/',
    'POST',
    data
  );
};
