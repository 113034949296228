import React from 'react';

export const GenericError = () => (
  <div>
    <h1>Sorry, an error has occurred.</h1>
    <p>
      Try refreshing the page or click <a href="/">here</a> to start again.
    </p>
  </div>
);

export default GenericError;
