import { IItemConfig } from '@dabapps/chadmin';
import { LabelPosition } from '@dabapps/chadmin/dist/edit-create/dynamic-forms/field-types/types';

const createSessionConfig: IItemConfig = {
  agreed_to_data_consent_obtained: {
    label:
      'I confirm that I, or the relative/friend on whose behalf I will complete the questionnaire, agree to participate and have personal data be used by Music for my Mind for the purposes described above.',
    type: 'boolean',
    fieldGroupClassName: 'checkbox',
    columnProps: {
      xs: 12,
    },
    blockFieldGroup: false,
    labelPosition: LabelPosition.AFTER_INPUT,
  },
  agreed_to_receive_email_notifications: {
    label:
      'I would like to receive email updates about Music for my Mind’s progress and activities.',
    type: 'boolean',
    fieldGroupClassName: 'checkbox',
    columnProps: {
      xs: 12,
    },
    blockFieldGroup: false,
    labelPosition: LabelPosition.AFTER_INPUT,
  },
};

export default createSessionConfig;
