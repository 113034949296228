import React from 'react';
import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { Column, SpacedGroup } from '@dabapps/roe';
import { connect, ResolveThunks } from 'react-redux';
import { isPending, hasFailed, getErrorData } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';

import { noop } from '^/common/utils';
import { getConfig } from './config';
import { setFeedback, SET_FEEDBACK } from './actions';
import { StoreState } from '^/store/types';
import { SetFeedbackData } from './types';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';
import {
  selectRelationTypes,
  selectInvolvementTypes,
  selectQuestionnaireExperienceTypes,
  selectDiscoveryTypes,
} from './selectors';
import { changeCurrentWizardPage } from '^/common/components/questionnaire/wizard/actions';
import { RESEARCH_QUESTIONNAIRE_ID } from '^/app/authenticated/questionnaire/const';
import { ResearchQuestionnairePageName } from '^/app/authenticated/questionnaire/types';
import { openModal } from '^/common/modals/actions';
import SubmitQuestionnaireModal from '^/common/components/questionnaire/musical-questionnaire/submit-questionnaire-modal';

interface OwnProps {
  sessionId: string;
  readonly?: boolean;
  isResearchQuestionnaire: boolean;
  initialData?: SetFeedbackData;
}

interface StateProps {
  isLoading: boolean;
  hasFailed: boolean;
  errors?: FormErrors;
  relationTypes: Record<string, string>;
  involvementTypes: Record<string, string>;
  discoveryTypes: Record<string, string>;
  questionnaireExperienceFeedbackTypes: Record<string, string>;
}

type DispatchProps = ResolveThunks<{
  setFeedback: typeof setFeedback;
  push: typeof push;
  changeCurrentWizardPage: typeof changeCurrentWizardPage;
  openModal: typeof openModal;
}>;

type Props = DispatchProps & StateProps & OwnProps;

export class Feedback extends React.PureComponent<Props> {
  private setFeedback = (data: SetFeedbackData) => {
    if (this.props.isResearchQuestionnaire) {
      this.props.openModal(
        <SubmitQuestionnaireModal
          data={data}
          isResearchQuestionnaire={this.props.isResearchQuestionnaire}
          sessionId={this.props.sessionId}
        />
      );
    }

    this.props.setFeedback(this.props.sessionId, data, false);
  };

  private updateFeedback = (data: Partial<SetFeedbackData>) => {
    if (this.props.initialData) {
      const newData = {
        ...this.props.initialData,
        ...data,
      };

      if (this.props.isResearchQuestionnaire) {
        this.props.openModal(
          <SubmitQuestionnaireModal
            data={newData}
            isResearchQuestionnaire={this.props.isResearchQuestionnaire}
            sessionId={this.props.sessionId}
          />
        );
      }

      this.props.setFeedback(
        this.props.sessionId,
        newData,
        this.props.isResearchQuestionnaire
      );
    }
  };

  private onClickCancel = () => {
    if (this.props.isResearchQuestionnaire) {
      return this.props.changeCurrentWizardPage(
        RESEARCH_QUESTIONNAIRE_ID,
        ResearchQuestionnairePageName.MUSICAL_PREFERENCES
      );
    }

    return this.props.push('/');
  };

  public render() {
    const config = getConfig(
      this.props.discoveryTypes,
      this.props.relationTypes,
      this.props.questionnaireExperienceFeedbackTypes
    );

    return (
      <div>
        <h3>Feedback (page 4 of 4)</h3>
        <hr />
        <Column md={6}>
          <AdminEditCreate
            formName="FEEDBACK"
            fields={Object.keys(config)}
            itemOptions={config}
            setPendingUploadInForm={noop}
            createItem={this.setFeedback}
            patchItem={this.updateFeedback}
            saveButtonClassName="secondary margin-vertical-base"
            saveButtonText="Next..."
            itemErrors={this.props.errors}
            readOnly={this.props.readonly}
            item={this.props.initialData}
            itemIsPending={this.props.isLoading}
            additionalFormControls={
              <SpacedGroup>
                <a
                  className="margin-left-large text-decoration-none uppercase font-weight-normal link-grey"
                  onClick={this.onClickCancel}
                >
                  {this.props.isResearchQuestionnaire
                    ? 'Previous Page'
                    : 'Cancel'}
                </a>
              </SpacedGroup>
            }
          />
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    isLoading: isPending(state.responses, SET_FEEDBACK),
    hasFailed: hasFailed(state.responses, SET_FEEDBACK),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(state.responses, SET_FEEDBACK)
    ),
    discoveryTypes: selectDiscoveryTypes(state),
    relationTypes: selectRelationTypes(state),
    involvementTypes: selectInvolvementTypes(state),
    questionnaireExperienceFeedbackTypes: selectQuestionnaireExperienceTypes(
      state
    ),
  };
}

export default connect(mapStateToProps, {
  setFeedback,
  push,
  changeCurrentWizardPage: changeCurrentWizardPage,
  openModal,
})(Feedback);
