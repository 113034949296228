import { AnyAction } from 'redux';

import { ResearchSessionQuestionnaireState } from './types';
import { GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA } from './actions';
import { SET_BACKGROUND_INFORMATION } from '^/common/components/questionnaire/musical-questionnaire/background-information/actions';
import { SET_PERSONALITY_AND_BEHAVIOUR } from '^/common/components/questionnaire/musical-questionnaire/personality-and-behaviour/actions';
import { SET_MUSICAL_PREFERENCES } from '^/common/components/questionnaire/musical-questionnaire/musical-preferences/actions';
import { SET_FEEDBACK } from '^/common/components/questionnaire/musical-questionnaire/feedback/actions';

export function researchQuestionnaire(
  state: ResearchSessionQuestionnaireState = {
    is_complete: false,
    pages: {
      BACKGROUND_INFORMATION: null,
      PERSONALITY_AND_BEHAVIOUR: null,
      MUSICAL_PREFERENCES: null,
      FEEDBACK: null,
    },
  },
  action: AnyAction
) {
  switch (action.type) {
    case SET_BACKGROUND_INFORMATION.REQUEST: {
      const updatedPagesForBackgroundInformation = {
        ...state.pages,
        BACKGROUND_INFORMATION: action.meta.data,
      };

      return {
        ...state,
        pages: updatedPagesForBackgroundInformation,
      };
    }
    case SET_PERSONALITY_AND_BEHAVIOUR.REQUEST: {
      const updatedPagesForPersonalityAndBehaviour = {
        ...state.pages,
        PERSONALITY_AND_BEHAVIOUR: action.meta.data,
      };

      return {
        ...state,
        pages: updatedPagesForPersonalityAndBehaviour,
      };
    }
    case SET_MUSICAL_PREFERENCES.REQUEST: {
      const updatedPagesForMusicalPreferences = {
        ...state.pages,
        MUSICAL_PREFERENCES: action.meta.data,
      };

      return {
        ...state,
        pages: updatedPagesForMusicalPreferences,
      };
    }
    case SET_FEEDBACK.REQUEST: {
      const updatedPagesForFeedback = {
        ...state.pages,
        FEEDBACK: action.meta.data,
      };

      return {
        ...state,
        pages: updatedPagesForFeedback,
      };
    }
    case GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
