import React from 'react';
import { InjectedFormProps, reduxForm, Field } from 'redux-form';
import { Button, FormGroup, SpacedGroup } from '@dabapps/roe';

import { VideoAnalysisFormData } from './types';
import FileField from '^/common/components/fields/file-field';

interface OwnProps {
  sessionId: string;
}

type Props = InjectedFormProps<VideoAnalysisFormData, OwnProps> & OwnProps;

export class UploadForm extends React.PureComponent<Props> {
  private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    this.props.handleSubmit(event);
  };

  public render() {
    return (
      <form onSubmit={this.onSubmit}>
        <FormGroup block>
          <label htmlFor="csv_file">CSV File</label>
          <div>
            <Field name="csv_file" component={FileField} />
          </div>
        </FormGroup>

        <SpacedGroup>
          <Button type="submit" className="primary">
            Submit Video Analysis
          </Button>
          <a
            className="margin-left-large text-decoration-none uppercase font-weight-normal primary"
            href={`/research/${this.props.sessionId}`}
          >
            Cancel
          </a>
        </SpacedGroup>
      </form>
    );
  }
}

export default reduxForm<VideoAnalysisFormData, OwnProps>({
  form: 'video-feedback-csv-upload',
})(UploadForm);
