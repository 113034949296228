import { AppRoot, Container, Footer } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';

import NotFound from '^/common/components/not-found';
import { StoreState } from '^/store/types';
import ExampleUserAppPage from '^/common/components/example-user-app-page';
import CreateSession from '^/app/unauthenticated/user/questionnaire/create-session';
import PublicUserQuestionnaire from '^/app/unauthenticated/user/questionnaire';
import LandingPage from '^/app/unauthenticated/user/landing/components';
import SpotifyPlaylistCreated from './user/questionnaire/spotify-playlist-created';
import YoutubePlaylistCreated from './user/questionnaire/youtube-playlist-created';
import NoPlaylistCreated from './user/questionnaire/no-playlist-created';
import PlaylistEmailed from './user/questionnaire/playlist-emailed';
import AppButton from '^/common/components/app-button';

type StateProps = Pick<StoreState, 'currentUser'>;

type RouteProps = RouteComponentProps<{}>;

type Props = RouteProps & StateProps;

const UserApp: React.FunctionComponent<Props> = React.memo(props => (
  <AppRoot>
    <div className="user-app-header-wrapper">
      <Container>
        <span className="user-app-header">
          <div className="logo">
            <Link to="/">
              <img src="/static/images/MFMM-Primary-Logo.svg" />
            </Link>
          </div>
          <div className="header-right">
            <span className="slogan">
              Bringing music to people living with dementia
            </span>
            <AppButton className="primary main-site-button">
              <a
                href="https://musicformymind.com/"
                target="_blank"
                className="white"
              >
                <FontAwesomeIcon icon={faGlobe} /> musicformymind.com
              </a>
            </AppButton>
          </div>
        </span>
      </Container>
    </div>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route
        path={`${props.match.path}example`}
        component={ExampleUserAppPage}
      />
      <Route exact path="/create-session" component={CreateSession} />
      <Route
        exact
        path="/session/:sessionId/no-playlist-created/"
        component={NoPlaylistCreated}
      />
      <Route
        exact
        path="/session/:sessionId/"
        component={PublicUserQuestionnaire}
      />
      <Route
        exact
        path="/session/:sessionId/spotify-playlist-created/"
        component={SpotifyPlaylistCreated}
      />
      <Route
        exact
        path="/session/:sessionId/youtube-playlist-created/"
        component={YoutubePlaylistCreated}
      />
      <Route
        exact
        path="/session/:sessionId/playlist-emailed/"
        component={PlaylistEmailed}
      />
      <Route component={NotFound} />
    </Switch>
    <Footer sticky>
      <Container>
        <p>
          <a target="_blank" href="https://musicformymind.com/">
            Music for My Mind
          </a>{' '}
          is a Charitable Incorporated organisation registered in England and
          Wales, registered number 1167246
        </p>
        <p>
          <a target="_blank" href="https://www.youtube.com/t/terms">
            YouTube Terms of Service
          </a>
        </p>
        <p>
          <a
            target="_blank"
            href="https://musicformymind.com/terms-of-service/"
          >
            Music for My Mind Terms of Service
          </a>
        </p>
        <p>
          <a
            target="_blank"
            href="https://musicformymind.com/about-us/privacy-policy/"
          >
            Privacy Policy
          </a>{' '}
          / MFMM © 2020 | All Rights Reserved
        </p>
      </Container>
    </Footer>
  </AppRoot>
));

const mapStateToProps = ({ currentUser }: StoreState): StateProps => {
  return {
    currentUser,
  };
};

export default withRouter(connect(mapStateToProps)(UserApp));
