import { getItemByName, GET_ITEM } from '@dabapps/redux-api-collections';
import { Container, Column, Row, Alert } from '@dabapps/roe';
import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { collections } from '^/common/collections';
import { StoreState } from '^/store/types';
import { ResearchSessionOverviewData } from '^/app/authenticated/session-overview/types';
import Loading from '^/common/components/loading';
import StatusIndicator from '^/common/components/status-indicator';
import {
  canAccessConsulteeConsentForm,
  canAccessResidentConsentForm,
  canAccessMusicQuestionnaire,
  canRunSession,
  canUploadVideoFeedback,
  canViewPlaylist,
} from '^/app/authenticated/session-overview/util';

const {
  actions: { getItem },
} = collections;

export type RouteParamProps = RouteComponentProps<{
  uid: string;
}>;

type DispatchProps = {
  getItem: typeof getItem;
};

type StateProps = {
  sessionData: ResearchSessionOverviewData | undefined;
  isLoading: boolean;
};

export type Props = RouteParamProps & DispatchProps & StateProps;

export class SessionOverview extends React.PureComponent<Props> {
  public componentDidMount() {
    this.getOverview();
  }

  private getOverview = () => {
    const researchSessionUid = this.props.match.params.uid;
    this.props.getItem('research-sessions/overview', researchSessionUid);
  };

  private onClickRefresh = this.getOverview;

  public render() {
    const { isLoading, sessionData } = this.props;

    return (
      <Container className="session-overview">
        {isLoading || !sessionData ? (
          <Loading />
        ) : (
          <>
            <div className="session-overview-heading">
              <h2 className="margin-bottom-none">
                {sessionData.resident_reference} - Session{' '}
                {sessionData.session_date}
              </h2>
              <p className="margin-top-small font-color-light">
                Location: {sessionData.location}{' '}
                {sessionData.birth_year && (
                  <>· Year of Birth: {sessionData.birth_year} </>
                )}
                <Link
                  to={`/research/update-session/${sessionData.id}`}
                  className="primary"
                >
                  Edit Details
                </Link>
              </p>
            </div>
            <h3 className="bold margin-bottom-none">1. Setup</h3>
            <Row>
              <Column md={4}>
                <ul className="session-overview-listing padding-none">
                  <li className="session-overview-listing-item padding-vertical-large margin-vertical-none">
                    {sessionData.is_resident_consent_form_completed && (
                      <Link
                        to={`/research/${sessionData.id}/resident-consent-form`}
                        className="primary"
                      >
                        View resident consent form
                      </Link>
                    )}
                    {sessionData.is_consultee_consent_form_completed && (
                      <Link
                        to={`/research/${sessionData.id}/consultee-consent-form`}
                        className="primary"
                      >
                        View consultee consent form
                      </Link>
                    )}
                    {!sessionData.is_resident_consent_form_completed &&
                      !sessionData.is_consultee_consent_form_completed && (
                        <>
                          Obtain consent for:{' '}
                          {canAccessConsulteeConsentForm(sessionData) ? (
                            <Link
                              to={`/research/${sessionData.id}/consultee-consent-form`}
                              className="primary"
                            >
                              Consultee
                            </Link>
                          ) : (
                            'Consultee'
                          )}{' '}
                          /{' '}
                          {canAccessResidentConsentForm(sessionData) ? (
                            <Link
                              to={`/research/${sessionData.id}/resident-consent-form`}
                              className="primary"
                            >
                              Resident
                            </Link>
                          ) : (
                            'Resident'
                          )}
                        </>
                      )}
                    <StatusIndicator
                      isComplete={
                        sessionData.is_consultee_consent_form_completed ||
                        sessionData.is_resident_consent_form_completed
                      }
                      className="float-right"
                    />
                  </li>
                  <li className="session-overview-listing-item padding-vertical-large margin-vertical-none">
                    {canAccessMusicQuestionnaire(sessionData) ? (
                      <Link
                        to={`/research/${this.props.match.params.uid}/musical-preferences`}
                        className="primary"
                      >
                        Fill in music questionnaire
                      </Link>
                    ) : (
                      'Fill in music questionnaire'
                    )}
                    <StatusIndicator
                      isComplete={sessionData.is_music_questionnaire_completed}
                      className="float-right"
                    />
                  </li>
                </ul>
              </Column>
            </Row>
            <h3 className="bold margin-bottom-none">2. Session</h3>
            <Row>
              <Column md={4}>
                {!sessionData.has_locked_observations &&
                  sessionData.is_music_questionnaire_completed &&
                  (sessionData.is_initial_playlist_complete ? (
                    <Alert>
                      <p>
                        Your 90 song playlist has now been generated. You can
                        now begin your session.
                      </p>
                    </Alert>
                  ) : (
                    <Alert>
                      <p>
                        Thank you for completing the survey. Your 90 song
                        playlist is now generating, please{' '}
                        <a onClick={this.onClickRefresh}>refresh</a> this page
                        for updates.
                      </p>
                    </Alert>
                  ))}
                <ul className="session-overview-listing padding-none">
                  <li className="session-overview-listing-item padding-vertical-large margin-vertical-none">
                    {canRunSession(sessionData) ? (
                      <Link
                        to={`/research/${this.props.match.params.uid}/run-session/`}
                        className="primary"
                      >
                        Run Session
                      </Link>
                    ) : (
                      'Run Session'
                    )}{' '}
                    /{' '}
                    {canRunSession(sessionData) ? (
                      <Link
                        to={`/research/${this.props.match.params.uid}/observe-session/`}
                        className="primary"
                      >
                        Observe Session
                      </Link>
                    ) : (
                      'Observe Session'
                    )}
                    <StatusIndicator
                      isComplete={sessionData.is_listening_session_completed}
                      className="float-right"
                    />
                  </li>
                  <li className="session-overview-listing-item padding-vertical-large margin-vertical-none">
                    {canUploadVideoFeedback(sessionData) ? (
                      <Link
                        to={`/research/${this.props.match.params.uid}/video-analysis-form/`}
                        className="primary"
                      >
                        Upload video feedback
                      </Link>
                    ) : (
                      'Upload video feedback'
                    )}
                    <StatusIndicator
                      isComplete={sessionData.is_video_feedback_uploaded}
                      className="float-right"
                    />
                  </li>
                  <li className="session-overview-listing-item padding-vertical-large margin-vertical-none">
                    {canViewPlaylist(sessionData) ? (
                      <Link
                        to={`/research/${this.props.match.params.uid}/playlist/`}
                        className="primary"
                      >
                        View playlist
                      </Link>
                    ) : (
                      'View playlist'
                    )}
                    <StatusIndicator
                      isComplete={
                        sessionData.is_final_playlist_and_results_complete
                      }
                      className="float-right"
                    />
                  </li>
                </ul>
              </Column>
            </Row>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ responses, items }: StoreState): StateProps => {
  return {
    sessionData: getItemByName(items, 'research-sessions/overview') as
      | ResearchSessionOverviewData
      | undefined,
    isLoading: isPending(responses, GET_ITEM, 'research-sessions/overview'),
  };
};

export default connect(mapStateToProps, {
  getItem,
})(SessionOverview);
