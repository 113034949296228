import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { ThunkDispatch } from '^/store/types';
import { GetCurrentPageForPublicSessionData } from '^/app/unauthenticated/user/questionnaire/types';
import { SetPersonalityAndBehaviourData } from './types';
import { showFormErrorToast } from '^/common/error-handling/utils';

export const SET_PERSONALITY_AND_BEHAVIOUR = makeAsyncActionSet(
  'SET_PERSONALITY_AND_BEHAVIOUR'
);
export function setPersonalityAndBehaviour(
  sessionId: string,
  data: SetPersonalityAndBehaviourData,
  isResearchSession: boolean
) {
  return (dispatch: ThunkDispatch) => {
    const url = `/api/${
      isResearchSession ? 'research-sessions' : 'sessions'
    }/${sessionId}/set-personality-and-behaviour/`;

    return request<GetCurrentPageForPublicSessionData>(
      SET_PERSONALITY_AND_BEHAVIOUR,
      url,
      'POST',
      data,
      {
        shouldRethrow: () => true,
        metaData: {
          data,
        },
      }
    )(dispatch)
      .then(() => {
        toast.success('Personality and music behaviour updated successfully.');
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
}
