import { Container, Row, Column, Section } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faList,
  faMusic,
} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';

import AppButton from '^/common/components/app-button';
import YoutubeEmbed from '^/common/components/youtube-embed';

const LandingPage: React.FunctionComponent = React.memo(() => (
  <div className="landing-page">
    <Section className="margin-horizontal-none">
      <Container>
        <Column md={10} mdOffset={1} className="padding-top-large">
          <h1 className="bold font-size-h4 padding-top-large">
            Create a personalised playlist for a relative or friend{' '}
            <span className="yellow-underline">
              living with recent memory loss or dementia.
            </span>
          </h1>
        </Column>
      </Container>
    </Section>
    <Section className="margin-horizontal-none">
      <Container>
        <Column md={10} mdOffset={1}>
          <Column lg={6} className="font-size-large padding-horizontal-none">
            <p className="line-height-larger margin-top-none padding-bottom-large">
              At Music for my Mind, we want to improve the quality of life for
              families affected by dementia, through the use of personalised
              music.
            </p>

            <p className="line-height-larger padding-bottom-large">
              This App provides a simple way to generate a favourite music
              playlist for you to listen to with your loved-one or friend living
              with dementia. Our research has identified the importance of
              popular music from the ages 13-19 yrs.
            </p>
          </Column>
          <Column lg={6}>
            <YoutubeEmbed
              embedId="9KTgAs1sKHs"
              title="Music for my Mind Charity Video"
            />
          </Column>
          <Column md={12} className="font-size-large padding-horizontal-none">
            <p className="line-height-larger padding-bottom-large">
              We’re using YouTube and Spotify to provide the playlist (we
              provide full instructions on how to add your playlist to existing
              accounts or create a new Spotify account if you don’t already have
              one).
            </p>

            <p className="line-height-larger padding-bottom-large">
              We're in the early stages of testing and you can help us develop
              this App further by sharing your thoughts on the experience and on
              the effects of the music.
            </p>
          </Column>
        </Column>
      </Container>
    </Section>
    <Section className="how-it-works margin-horizontal-none">
      <Container>
        <h2 className="how-it-works-title text-align-center padding-bottom-large bold">
          How it works
        </h2>
        <Column md={10} mdOffset={1}>
          <Column md={4} className="text-align-center">
            <div className="how-it-works-inner margin-vertical-large">
              <h5 className="how-it-works-step-number margin-horizontal-auto font-size-large padding-vertical-small">
                Step 1
              </h5>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="how-it-works-icon primary margin-vertical-large"
              />
              <h4 className="how-it-works-heading font-size-large bold primary">
                Fill out questionnaire
              </h4>
              <p className="how-it-works-body margin-horizontal-auto font-size-large font-color-light">
                Answer simple questions on behalf of your relative/friend
              </p>
            </div>
          </Column>
          <Column md={4} className="text-align-center">
            <div className="how-it-works-inner margin-vertical-large">
              <h5 className="how-it-works-step-number margin-horizontal-auto font-size-large padding-vertical-small">
                Step 2
              </h5>
              <FontAwesomeIcon
                icon={faList}
                className="how-it-works-icon primary margin-vertical-large"
              />
              <h4 className="how-it-works-heading font-size-large bold primary">
                Generate playlist
              </h4>
              <p className="how-it-works-body margin-horizontal-auto font-size-large font-color-light">
                We create a playlist using YouTube or Spotify and email you a
                link and instructions to access it.
              </p>
            </div>
          </Column>
          <Column md={4} className="text-align-center">
            <div className="how-it-works-inner margin-vertical-large">
              <h5 className="how-it-works-step-number margin-horizontal-auto font-size-large padding-vertical-small">
                Step 3
              </h5>
              <FontAwesomeIcon
                icon={faMusic}
                className="how-it-works-icon primary margin-vertical-large"
              />
              <h4 className="how-it-works-heading font-size-large bold primary">
                Listen together
              </h4>
              <p className="how-it-works-body margin-horizontal-auto font-size-large font-color-light">
                Listen to the playlist together and enjoy!
              </p>
            </div>
          </Column>
          <Link to="/create-session/" className="text-decoration-none">
            <AppButton className="button-call-to-action display-block margin-horizontal-auto bold font-size-h1 padding-large">
              Start Questionnaire
            </AppButton>
          </Link>
          <p className="text-align-center limit-width margin-horizontal-auto font-size-large">
            The questionnaire should take around{' '}
            <span className="bold">15 minutes to complete</span> then you’ll be
            given a link to access your playlist
          </p>
        </Column>
      </Container>
    </Section>
    <Section className="margin-horizontal-none">
      <Container>
        <Row>
          <div className="font-size-large text-align-center margin-horizontal-auto margin-vertical-large padding-vertical-large questionnaire-download">
            <b>Please note:</b> If you don’t think you can fill out the
            questionnaire in one sitting, can we suggest that you start by{' '}
            <a
              className="font-color-base bold"
              target="_blank"
              href="/static/pdfs/landing/Music_for_my_Mind_-_Playlist_Questionnaire.pdf"
            >
              printing out the questionnaire
            </a>{' '}
            and going through the questions with your relative with memory loss.
            Once you have the answers, you can come back and fill it out in one
            sitting.
          </div>
        </Row>
      </Container>
    </Section>
  </div>
));

export default LandingPage;
