import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { getItemByName } from '@dabapps/redux-api-collections/dist/items';
import { anyPending, getErrorData } from '@dabapps/redux-requests';
import { Container, Column } from '@dabapps/roe';
import React from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  editSessionAndRedirectToSessionListing,
  EDIT_SESSION_AND_REDIRECT_TO_SESSION_LISTING,
} from '^/app/authenticated/session-management/update-session/actions';
import { addEditSessionFormConfig } from '^/app/authenticated/session-management/form-config';
import { UpdateResearchSessionData } from '^/app/authenticated/session-management/types';
import { noop } from '^/common/utils';
import PageHeader from '^/common/components/page-header';
import { StoreState } from '^/store/types';
import { collections } from '^/common/collections';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';

const {
  actions: { getItem },
} = collections;

interface StateProps {
  sessionData: UpdateResearchSessionData | undefined;
  isLoading: boolean;
  errors: FormErrors | undefined;
}

export type RouteParamProps = RouteComponentProps<{
  uid: string;
}>;

type DispatchProps = ResolveThunks<{
  getItem: typeof getItem;
  editSessionAndRedirectToSessionListing: typeof editSessionAndRedirectToSessionListing;
}>;

type Props = StateProps & DispatchProps & RouteParamProps;

export class UpdateSession extends React.PureComponent<Props> {
  public componentDidMount() {
    const researchSessionUid = this.props.match.params.uid;
    this.props.getItem('research-sessions', researchSessionUid);
  }

  public render() {
    return (
      <Container>
        <PageHeader>Update Session</PageHeader>
        <Column md={6}>
          {this.props.sessionData ? (
            <AdminEditCreate
              fields={Object.keys(addEditSessionFormConfig)}
              formName="ADD_SESSION"
              itemOptions={addEditSessionFormConfig}
              setPendingUploadInForm={noop}
              saveButtonText="Save session"
              saveButtonClassName="primary margin-vertical-base"
              patchItem={this.formSubmit}
              additionalFormControls={
                <a
                  href="/research"
                  className="text-decoration-none primary padding-large link-grey"
                >
                  Cancel
                </a>
              }
              itemErrors={this.props.errors}
              loading={this.props.isLoading}
              item={this.props.sessionData}
            />
          ) : (
            <div>Loading session data</div>
          )}
        </Column>
      </Container>
    );
  }

  private formSubmit = (formData: Partial<UpdateResearchSessionData>) => {
    this.props.editSessionAndRedirectToSessionListing(
      this.props.match.params.uid,
      formData
    );
  };
}

const mapStateToProps = ({ responses, items }: StoreState): StateProps => {
  return {
    sessionData: getItemByName(items, 'research-sessions') as
      | UpdateResearchSessionData
      | undefined,
    isLoading: anyPending(responses, [
      EDIT_SESSION_AND_REDIRECT_TO_SESSION_LISTING,
    ]),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(responses, EDIT_SESSION_AND_REDIRECT_TO_SESSION_LISTING)
    ),
  };
};

export default connect(mapStateToProps, {
  editSessionAndRedirectToSessionListing,
  getItem,
})(UpdateSession);
