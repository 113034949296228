import { Modal, ModalBody, Button } from '@dabapps/roe';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { push } from 'connected-react-router';

import { closeModal } from '^/common/modals/actions';
import { endSessionForAll } from '^/app/authenticated/listening-session/observation/actions';

interface StateProps {
  sessionId: string;
}

type DispatchProps = ResolveThunks<{
  closeModal: typeof closeModal;
  endSessionForAll: typeof endSessionForAll;
  push: typeof push;
}>;

type Props = DispatchProps & StateProps;

export class EndSessionModal extends React.PureComponent<Props> {
  private onClickOutside = () => {
    this.props.closeModal();
  };

  private onClickSubmit = () => {
    this.props.endSessionForAll(this.props.sessionId).then(() => {
      this.props.closeModal();
      this.props.push(`/research/${this.props.sessionId}/`);
    });
  };

  private onClickNo = () => {
    this.props.closeModal();
  };

  public render() {
    return (
      <Modal onClickOutside={this.onClickOutside}>
        <ModalBody>
          <div className="padding-large text-align-center">
            <p>Are you sure you want to end the session?</p>
            <p>Once you end it you (and others) can't log any more data.</p>
            <div>
              <Button onClick={this.onClickSubmit} className="primary">
                Yes - End Session
              </Button>
            </div>
            <div className="padding-vertical-base">
              <a onClick={this.onClickNo}>No - Continue logging results</a>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(null, {
  closeModal,
  endSessionForAll,
  push,
})(EndSessionModal);
