import React from 'react';

import AuthWrapper from '^/app/unauthenticated/auth/components/wrapper';
import PageHeader from '^/common/components/page-header';

const ForgotPasswordEmailSent: React.FunctionComponent<{}> = () => (
  <AuthWrapper>
    <PageHeader>Forgot password email sent</PageHeader>
    <p>We've sent an email to the address you provided.</p>
    <p>Click on the link in the email to set a new password.</p>
  </AuthWrapper>
);

export default ForgotPasswordEmailSent;
