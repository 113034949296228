import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import AppButton from '^/common/components/app-button';

interface Props {
  titleName: string;
  descriptionName: string;
  icon: {
    faIcon: IconProp;
    classNames: string;
  };
  link: string;
}

class YoutubeChannelPlaylistTile extends React.PureComponent<Props> {
  public render() {
    const { titleName, descriptionName, icon, link } = this.props;

    return (
      <div className="external-service-tile">
        <h1 className="title">Created your {titleName} channel?</h1>
        <p className="subtitle">
          <b>If you now have a {descriptionName} channel:</b>
          <br />
          Click below to create your playlist!
        </p>
        <a href={link} target="_blank">
          <AppButton className="playlist-button">
            <FontAwesomeIcon icon={icon.faIcon} className={icon.classNames} />
            <span className="link">create my playlist</span>
          </AppButton>
        </a>
      </div>
    );
  }
}

export default YoutubeChannelPlaylistTile;
