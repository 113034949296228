import { AnyAction } from 'redux';

import { GET_CURRENT_PAGE_FOR_PUBLIC_SESSION } from '^/app/unauthenticated/user/questionnaire/actions';
import { CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION } from '^/app/unauthenticated/user/questionnaire/create-session/actions';
import {
  QuestionnaireWizardState,
  PublicUserSessionPageName,
} from '^/common/components/questionnaire/wizard/types';
import { PUBLIC_USER_QUESTIONNAIRE_ID } from '^/app/unauthenticated/user/questionnaire/const';
import { UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS } from '^/app/unauthenticated/user/questionnaire/contact-details/actions';
import { SET_PERSONALITY_AND_BEHAVIOUR } from '^/common/components/questionnaire/musical-questionnaire/personality-and-behaviour/actions';
import { SET_BACKGROUND_INFORMATION } from '^/common/components/questionnaire/musical-questionnaire/background-information/actions';
import { SET_MUSICAL_PREFERENCES } from '^/common/components/questionnaire/musical-questionnaire/musical-preferences/actions';
import { SET_FEEDBACK } from '^/common/components/questionnaire/musical-questionnaire/feedback/actions';
import { CHANGE_CURRENT_WIZARD_PAGE } from './actions';
import { RESEARCH_QUESTIONNAIRE_ID } from '^/app/authenticated/questionnaire/const';

export function questionnaireWizard(
  state: QuestionnaireWizardState = {},
  action: AnyAction
): QuestionnaireWizardState {
  switch (action.type) {
    case CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION.SUCCESS:
      return {
        ...state,
        [PUBLIC_USER_QUESTIONNAIRE_ID]:
          PublicUserSessionPageName.NAME_AND_EMAIL,
      };
    case SET_FEEDBACK.SUCCESS:
    case SET_MUSICAL_PREFERENCES.SUCCESS:
    case SET_BACKGROUND_INFORMATION.SUCCESS:
    case SET_PERSONALITY_AND_BEHAVIOUR.SUCCESS:
    case GET_CURRENT_PAGE_FOR_PUBLIC_SESSION.SUCCESS:
    case UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS.SUCCESS:
      return {
        ...state,
        [PUBLIC_USER_QUESTIONNAIRE_ID]: action.payload.data.current_page,
        [RESEARCH_QUESTIONNAIRE_ID]: action.payload.data.current_page,
      };
    case CHANGE_CURRENT_WIZARD_PAGE:
      return {
        ...state,
        [action.questionnaireId]: action.pageName,
      };
    default:
      return state;
  }
}
