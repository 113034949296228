import React from 'react';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps;

export class FileField extends React.PureComponent<Props> {
  public render() {
    return <input type="file" onChange={this.props.input.onChange} />;
  }
}

export default FileField;
