import { AnyAction } from 'redux';

import {
  CREATE_OBSERVATION,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  UPDATE_OBSERVATION,
} from './actions';

export function observationFormChanged(state = false, action: AnyAction) {
  if (
    action.type === '@@redux-form/CHANGE' &&
    action.meta.form === 'observation'
  ) {
    return true;
  }

  if (
    action.type === CREATE_OBSERVATION.SUCCESS ||
    action.type === UPDATE_OBSERVATION.SUCCESS
  ) {
    return false;
  }

  if (action.type === PREVIOUS_PAGE) {
    return false;
  }

  return state;
}

export function observationPage(state = 0, action: AnyAction) {
  switch (action.type) {
    case NEXT_PAGE:
      return state + 1;
    case PREVIOUS_PAGE:
      return state - 1;
    default:
      return state;
  }
}
