import { SimpleRecord } from '@dabapps/simple-records';

export type CreateResearchSessionData = {
  resident_reference: string;
  location: string;
  year_of_birth: string;
  session_date: Date;
};

export type CreateUpdateResearchSessionResponseData = {
  session_id: string;
};

export type UpdateResearchSessionData = {
  id: string;
  resident_reference: string;
  location: string;
  session_date: string;
};

export const UpdateResearchSessionData = SimpleRecord<
  UpdateResearchSessionData
>({
  id: '',
  resident_reference: '',
  location: '',
  session_date: '',
});
