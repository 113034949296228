import React, { useEffect } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { Container } from '@dabapps/roe';
import {
  isPending,
  hasFailed,
  getErrorData,
  resetRequestState,
} from '@dabapps/redux-requests';

import {
  createPublicSessionAndRedirectToFirstSection,
  CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION,
} from '^/app/unauthenticated/user/questionnaire/create-session/actions';
import createSessionConfig from '^/app/unauthenticated/user/questionnaire/create-session/config';
import { noop } from '^/common/utils';
import { StoreState } from '^/store/types';
import GenericError from '^/common/components/generic-error';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';
import { UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS } from '^/app/unauthenticated/user/questionnaire/contact-details/actions';

type DispatchProps = ResolveThunks<{
  createPublicSessionAndRedirectToFirstSection: typeof createPublicSessionAndRedirectToFirstSection;
  resetRequestState: typeof resetRequestState;
}>;

interface StateProps {
  isLoading: boolean;
  hasFailed: boolean;
  errors?: FormErrors;
}

type Props = StateProps & DispatchProps;

export const CreateSession: React.FunctionComponent<Props> = React.memo(
  props => {
    useEffect(() => {
      props.resetRequestState(UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS);
    });

    if (props.hasFailed && !props.errors) {
      return <GenericError />;
    }

    return (
      <Container>
        <div>
          <h1>Please read and agree to our data usage statement</h1>
          <hr />
          <p>
            We collect your data to create and deliver your personalised
            playlist and to contact you for feedback and support.
          </p>
          <p>
            Your data is stored and processed in accordance with the General
            Data Protection Regulation (GDPR), other relevant UK legislation and
            Music for my Mind's{' '}
            <a
              href="https://musicformymind.com/about-us/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p>
            For more information, read our{' '}
            <a
              href="https://musicformymind.com/faqs-about-playlist-maker/"
              target="_blank"
            >
              Frequently Asked Questions
            </a>
            .
          </p>
          <AdminEditCreate
            formName="CREATE_USER_SESSION"
            fields={Object.keys(createSessionConfig)}
            createItem={props.createPublicSessionAndRedirectToFirstSection}
            itemOptions={createSessionConfig}
            setPendingUploadInForm={noop}
            itemErrors={props.errors}
            itemIsPending={props.isLoading}
            saveButtonClassName="secondary"
            saveButtonText="Next..."
            additionalFormControls={
              <a
                className="margin-left-large text-decoration-none uppercase font-weight-normal link-grey"
                href="/"
              >
                Cancel
              </a>
            }
          />
          <p>
            <strong>Please note:</strong> You have the right to 'opt out' at any
            time, and can do so by emailing{' '}
            <a href="mailto:info@musicformymind.com">info@musicformymind.com</a>
          </p>
        </div>
      </Container>
    );
  }
);

function mapStateToProps(state: StoreState): StateProps {
  return {
    isLoading: isPending(
      state.responses,
      CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION
    ),
    hasFailed: hasFailed(
      state.responses,
      CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION
    ),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(
        state.responses,
        CREATE_PUBLIC_SESSION_AND_REDIRECT_TO_FIRST_SECTION
      )
    ),
  };
}

export default connect(mapStateToProps, {
  createPublicSessionAndRedirectToFirstSection,
  resetRequestState,
})(CreateSession);
