import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { anyPending, hasFailed } from '@dabapps/redux-requests';

import QuestionnaireWizard from '^/common/components/questionnaire/wizard';
import { StoreState } from '^/store/types';
import MusicalPreferences from '^/common/components/questionnaire/musical-questionnaire/musical-preferences';
import { ResearchQuestionnairePageName } from './types';
import {
  getQuestionnaireOptions,
  GET_QUESTIONNAIRE_OPTIONS,
} from '^/common/components/questionnaire/musical-questionnaire/actions';
import Loading from '^/common/components/loading';
import GenericError from '^/common/components/generic-error';
import BackgroundInformation from '^/common/components/questionnaire/musical-questionnaire/background-information';
import PersonalityAndBehaviour from '^/common/components/questionnaire/musical-questionnaire/personality-and-behaviour';
import Feedback from '^/common/components/questionnaire/musical-questionnaire/feedback';
import {
  getResearchSessionQuestionnaireData,
  GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA,
} from '^/app/authenticated/questionnaire/actions';
import { RESEARCH_QUESTIONNAIRE_ID } from './const';
import {
  selectIsQuestionnaireReadonly,
  selectIsQuestionnairePageData,
} from './selectors';
import { QuestionnaireData } from '^/common/components/questionnaire/musical-questionnaire/types';
import { changeCurrentWizardPage } from '^/common/components/questionnaire/wizard/actions';

type DispatchProps = ResolveThunks<{
  getQuestionnaireOptions: typeof getQuestionnaireOptions;
  getResearchSessionQuestionnaireData: typeof getResearchSessionQuestionnaireData;
  changeCurrentWizardPage: typeof changeCurrentWizardPage;
}>;

interface StateProps {
  isLoading: boolean;
  hasFailed: boolean;
  isReadonly: boolean;
  researchQuestionnaireData: QuestionnaireData;
}

type RouteProps = RouteComponentProps<{ sessionId: string }>;

type Props = StateProps & RouteProps & DispatchProps;

export class ResearchQuestionnaire extends React.Component<Props> {
  public componentDidMount() {
    this.props.getQuestionnaireOptions();
    this.props.getResearchSessionQuestionnaireData(
      this.props.match.params.sessionId
    );
    this.props.changeCurrentWizardPage(
      RESEARCH_QUESTIONNAIRE_ID,
      ResearchQuestionnairePageName.BACKGROUND_INFORMATION
    );
  }

  public render() {
    if (this.props.isLoading) {
      return <Loading />;
    }

    if (this.props.hasFailed) {
      return <GenericError />;
    }

    return (
      <div>
        <QuestionnaireWizard
          questionnaireId={RESEARCH_QUESTIONNAIRE_ID}
          pages={[
            {
              name: ResearchQuestionnairePageName.BACKGROUND_INFORMATION,
              title: 'Music Questionnaire',
              pageContent: (
                <BackgroundInformation
                  isResearchQuestionnaire
                  sessionId={this.props.match.params.sessionId}
                  readonly={this.props.isReadonly}
                  initialData={
                    this.props.researchQuestionnaireData.BACKGROUND_INFORMATION
                      ? this.props.researchQuestionnaireData
                          .BACKGROUND_INFORMATION
                      : undefined
                  }
                />
              ),
            },
            {
              name: ResearchQuestionnairePageName.PERSONALITY_AND_BEHAVIOUR,
              title: 'Music Questionnaire',
              pageContent: (
                <PersonalityAndBehaviour
                  isResearchQuestionnaire
                  sessionId={this.props.match.params.sessionId}
                  readonly={this.props.isReadonly}
                  initialData={
                    this.props.researchQuestionnaireData
                      .PERSONALITY_AND_BEHAVIOUR
                      ? this.props.researchQuestionnaireData
                          .PERSONALITY_AND_BEHAVIOUR
                      : undefined
                  }
                />
              ),
            },
            {
              name: ResearchQuestionnairePageName.MUSICAL_PREFERENCES,
              title: 'Music Questionnaire',
              pageContent: (
                <MusicalPreferences
                  isResearchQuestionnaire
                  sessionId={this.props.match.params.sessionId}
                  readonly={this.props.isReadonly}
                  initialData={
                    this.props.researchQuestionnaireData.MUSICAL_PREFERENCES
                      ? this.props.researchQuestionnaireData.MUSICAL_PREFERENCES
                      : undefined
                  }
                />
              ),
            },
            {
              name: ResearchQuestionnairePageName.FEEDBACK,
              title: 'Music Questionnaire',
              pageContent: (
                <Feedback
                  isResearchQuestionnaire
                  sessionId={this.props.match.params.sessionId}
                  readonly={this.props.isReadonly}
                  initialData={
                    this.props.researchQuestionnaireData.FEEDBACK
                      ? this.props.researchQuestionnaireData.FEEDBACK
                      : undefined
                  }
                />
              ),
            },
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    questionnaireOptions: state.questionnaireOptions,
    isLoading: anyPending(state.responses, [
      GET_QUESTIONNAIRE_OPTIONS,
      GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA,
    ]),
    hasFailed:
      hasFailed(state.responses, GET_QUESTIONNAIRE_OPTIONS) ||
      hasFailed(state.responses, GET_RESEARCH_SESSION_QUESTIONNAIRE_DATA),
    isReadonly: selectIsQuestionnaireReadonly(state),
    researchQuestionnaireData: selectIsQuestionnairePageData(state),
  };
}

export default connect(mapStateToProps, {
  getQuestionnaireOptions,
  getResearchSessionQuestionnaireData,
  changeCurrentWizardPage: changeCurrentWizardPage,
})(ResearchQuestionnaire);
