import { SimpleRecord } from '@dabapps/simple-records';

export type ResidentConsentFormData = {
  id: string;
  resident_name: string;
  friend_or_relative_name: string;
  witnessed_by: string;
  name_of_researcher: string;
  resident_has_confirmed_information_sheet: boolean;
  resident_has_confirmed_right_to_withdrawal: boolean;
  resident_has_given_permission_for_filming: boolean;
  resident_has_given_permission_for_data_to_be_retained: boolean;
  resident_has_agreed_to_take_part_in_study: boolean;
  friend_or_relative_has_confirmed_information_sheet: boolean;
  friend_or_relative_has_confirmed_right_to_withdrawal: boolean;
  friend_or_relative_has_given_permission_for_filming: boolean;
  friend_or_relative_has_given_permission_for_data_to_be_retained: boolean;
  friend_or_relative_has_agreed_to_take_part_in_study: boolean;
};

export const ResidentConsentFormData = SimpleRecord<ResidentConsentFormData>({
  id: '',
  resident_name: '',
  friend_or_relative_name: '',
  witnessed_by: '',
  name_of_researcher: '',
  resident_has_confirmed_information_sheet: false,
  resident_has_confirmed_right_to_withdrawal: false,
  resident_has_given_permission_for_filming: false,
  resident_has_given_permission_for_data_to_be_retained: false,
  resident_has_agreed_to_take_part_in_study: false,
  friend_or_relative_has_confirmed_information_sheet: false,
  friend_or_relative_has_confirmed_right_to_withdrawal: false,
  friend_or_relative_has_given_permission_for_filming: false,
  friend_or_relative_has_given_permission_for_data_to_be_retained: false,
  friend_or_relative_has_agreed_to_take_part_in_study: false,
});

export type ResidentConsentFormResponseData = {
  session_id: string;
};
