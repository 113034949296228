import { AnyAction } from 'redux';

import { GET_QUESTIONNAIRE_OPTIONS } from '^/common/components/questionnaire/musical-questionnaire/actions';
import { QuestionnaireOptionsState } from '^/app/unauthenticated/user/questionnaire/types';

export function questionnaireOptions(
  state = {
    country_options: {},
    personality_types: {},
    music_based_activities: {},
    faith_options: {},
    music_types_options: {},
    music_type_sub_genre_options: { classical: {} },
    music_importance_options: {},
    how_did_you_discover_options: {},
    person_assisting_relation_options: {},
    person_assisting_involvement_options: {},
    questionnaire_experience_feedback_options: {},
  },
  action: AnyAction
): QuestionnaireOptionsState {
  switch (action.type) {
    case GET_QUESTIONNAIRE_OPTIONS.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
