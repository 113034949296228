import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import { ThunkDispatch } from '^/store/types';
import { SetFeedbackData } from './types';
import { GetCurrentPageForPublicSessionData } from '^/app/unauthenticated/user/questionnaire/types';
import { showFormErrorToast } from '^/common/error-handling/utils';
import { closeModal } from '^/common/modals/actions';

export const SET_FEEDBACK = makeAsyncActionSet('SET_FEEDBACK');
export function setFeedback(
  sessionId: string,
  data: SetFeedbackData,
  isResearchQuestionnaire: boolean
) {
  return (dispatch: ThunkDispatch) => {
    const url = `/api/${
      isResearchQuestionnaire ? 'research-sessions' : 'sessions'
    }/${sessionId}/set-feedback/`;

    return request<GetCurrentPageForPublicSessionData>(
      SET_FEEDBACK,
      url,
      'POST',
      data,
      {
        shouldRethrow: () => true,
        metaData: {
          data,
        },
      }
    )(dispatch)
      .then(() => {
        toast.success('Feedback updated successfully.');
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
}

export function setFeedbackAndRedirectToSessionOverview(
  sessionId: string,
  data: SetFeedbackData
) {
  return (dispatch: ThunkDispatch) => {
    return setFeedback(
      sessionId,
      data,
      true
    )(dispatch).then(() => {
      dispatch(closeModal());
      dispatch(push(`/research/${sessionId}`));
    });
  };
}
