import { request, makeAsyncActionSet } from '@dabapps/redux-requests';

import { ThunkDispatch } from '^/store/types';

export const GET_QUESTIONNAIRE_OPTIONS = makeAsyncActionSet(
  'GET_QUESTIONNAIRE_OPTIONS'
);
export function getQuestionnaireOptions() {
  return (dispatch: ThunkDispatch) => {
    return request(
      GET_QUESTIONNAIRE_OPTIONS,
      `/api/sessions/get-questionnaire-options`,
      'GET',
      undefined,
      {
        shouldRethrow: () => true,
      }
    )(dispatch);
  };
}
