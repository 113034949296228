import { AnyAction } from 'redux';

import { CLOSE_ALL_MODALS, CLOSE_MODAL, OPEN_MODAL } from './actions';
import { StoreState } from '^/store/types';

export const modals = (state: StoreState['modals'] = [], action: AnyAction) => {
  switch (action.type) {
    case OPEN_MODAL:
      return [...state, action.payload];
    case CLOSE_MODAL:
      return state.slice(0, -1);
    case CLOSE_ALL_MODALS:
      return [];
    default:
      return state;
  }
};
