import { SimpleRecord } from '@dabapps/simple-records';
import { AxiosError, AxiosResponse } from 'axios';
import { ThunkAction } from 'redux-thunk';

interface Action<Payload, Meta> {
  type: string;
  payload?: Payload;
  error?: boolean;
  meta?: Meta;
}

export type AnyThunkActionOrAction =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Action<any, any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ThunkAction<any, any, undefined, any>;

export interface MusicPlayerSong {
  title: string;
  artist: string;
  preview_url: string;
}

export type Song = {
  bpm: number;
  title: string;
  artist: string;
  preview_url: string;
  spotify_uri: string;
};

export type PlaylistData = {
  id: string;
  playlist: Array<Song>;
  playlist_name: string;
};

export const PlaylistData = SimpleRecord<PlaylistData>({
  id: '',
  playlist: [
    {
      bpm: 0,
      title: '',
      artist: '',
      preview_url: '',
      spotify_uri: '',
    },
  ],
  playlist_name: '',
});

export function isAxiosResponse(responseState: AxiosResponse | AxiosError) {
  return (responseState as AxiosResponse).data !== undefined;
}
