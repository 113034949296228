import React from 'react';
import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { Column, Button, SpacedGroup } from '@dabapps/roe';
import { connect, ResolveThunks } from 'react-redux';
import { hasFailed, getErrorData, isPending } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';

import { noop } from '^/common/utils';
import { getConfig } from '^/common/components/questionnaire/musical-questionnaire/background-information/config';
import {
  setBackgroundInformation,
  SET_BACKGROUND_INFORMATION,
} from './actions';
import { StoreState } from '^/store/types';
import { SetBackgroundInformationData } from '^/common/components/questionnaire/musical-questionnaire/background-information/types';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';
import { changeCurrentWizardPage } from '^/common/components/questionnaire/wizard/actions';
import { RESEARCH_QUESTIONNAIRE_ID } from '^/app/authenticated/questionnaire/const';
import { ResearchQuestionnairePageName } from '^/app/authenticated/questionnaire/types';
import { selectCountryOptions } from './selectors';

interface OwnProps {
  sessionId: string;
  readonly?: boolean;
  initialData?: SetBackgroundInformationData;
  isResearchQuestionnaire: boolean;
}

interface StateProps {
  isLoading: boolean;
  hasFailed: boolean;
  errors?: FormErrors;
  countryOptions: Record<string, string>;
}

type DispatchProps = ResolveThunks<{
  setBackgroundInformation: typeof setBackgroundInformation;
  changeCurrentWizardPage: typeof changeCurrentWizardPage;
  push: typeof push;
}>;

type Props = DispatchProps & StateProps & OwnProps;

export class BackgroundInformation extends React.PureComponent<Props> {
  private setBackgroundInformation = (data: SetBackgroundInformationData) => {
    this.props.setBackgroundInformation(
      this.props.sessionId,
      data,
      this.props.isResearchQuestionnaire
    );
  };

  private updateBackgroundInformation = (
    data: Partial<SetBackgroundInformationData>
  ) => {
    if (this.props.initialData) {
      const newData = {
        ...this.props.initialData,
        ...data,
      };

      this.setBackgroundInformation(newData);
    }
  };

  private onClickCancel = () => {
    if (this.props.isResearchQuestionnaire) {
      return this.props.push('/research');
    }

    return this.props.push('/');
  };

  private onClickNext = () => {
    this.props.changeCurrentWizardPage(
      RESEARCH_QUESTIONNAIRE_ID,
      ResearchQuestionnairePageName.PERSONALITY_AND_BEHAVIOUR
    );
  };

  public render() {
    const backgroundInformationConfig = getConfig(this.props.countryOptions);

    return (
      <div>
        <p>
          In order to create a personal playlist for yourself or your loved one
          living with memory loss or dementia, we would like to ask you some
          questions.
        </p>
        <p>
          There are no right or wrong answers, just answer to the best of your
          abilities. Please note that some of the questions may refer to the
          period when you or your loved one were between the ages of 13 and 19.
        </p>

        <h3>Background information (page 1 of 4)</h3>
        <p>
          <strong>
            The following questions refer to the music listener's background.
          </strong>
        </p>
        <hr />
        <Column md={6}>
          <AdminEditCreate
            formName="BACKGROUND_INFORMATION"
            fields={Object.keys(backgroundInformationConfig)}
            itemOptions={backgroundInformationConfig}
            setPendingUploadInForm={noop}
            createItem={this.setBackgroundInformation}
            patchItem={this.updateBackgroundInformation}
            saveButtonClassName="secondary margin-vertical-base"
            saveButtonText="Next..."
            itemErrors={this.props.errors}
            readOnly={this.props.readonly}
            itemIsPending={this.props.isLoading}
            item={this.props.initialData}
            additionalFormControls={
              <SpacedGroup>
                {this.props.isResearchQuestionnaire && this.props.readonly && (
                  <Button className="primary" onClick={this.onClickNext}>
                    Next Page
                  </Button>
                )}
                <a
                  className="margin-left-large text-decoration-none uppercase font-weight-normal link-grey"
                  onClick={this.onClickCancel}
                >
                  {this.props.isResearchQuestionnaire
                    ? 'Previous Page'
                    : 'Cancel'}
                </a>
              </SpacedGroup>
            }
          />
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    isLoading: isPending(state.responses, SET_BACKGROUND_INFORMATION),
    hasFailed: hasFailed(state.responses, SET_BACKGROUND_INFORMATION),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(state.responses, SET_BACKGROUND_INFORMATION)
    ),
    countryOptions: selectCountryOptions(state),
  };
}

export default connect(mapStateToProps, {
  setBackgroundInformation,
  changeCurrentWizardPage: changeCurrentWizardPage,
  push,
})(BackgroundInformation);
