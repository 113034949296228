import React from 'react';

interface Props {
  embedId: string;
  title: string;
}

const YoutubeEmbed: React.FunctionComponent<Props> = React.memo(
  ({ embedId, title }) => (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      />
    </div>
  )
);

export default YoutubeEmbed;
