import React from 'react';

interface Props {
  className?: string;
}

const AppButton: React.FunctionComponent<Props> = React.memo(props => (
  <button className={props.className}>{props.children}</button>
));

export default AppButton;
