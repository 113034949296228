import { getItemByName, GET_ITEM } from '@dabapps/redux-api-collections';
import { isPending } from '@dabapps/redux-requests';
import { Container, Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';

import { collections } from '^/common/collections';
import { PlaylistData } from '^/common/types';
import { StoreState } from '^/store/types';
import Loading from '^/common/components/loading';
import { updatePlaylistAndReloadPage } from '^/app/unauthenticated/user/questionnaire/results/actions';
import ResultsScreenSong from '^/app/unauthenticated/user/questionnaire/results/results-screen-song';
import MusicPlayer from '^/common/components/music-player';
import MusicServiceTile from './music-service-tile';
import { FEATURE_FLAGS } from '^/utils/template';
import PlaylistName from './playlist-name';

const {
  actions: { getItem },
} = collections;

type DispatchProps = ResolveThunks<{
  getItem: typeof getItem;
  updatePlaylistAndReloadPage: typeof updatePlaylistAndReloadPage;
}>;

interface OwnProps {
  sessionId: string;
}

type StateProps = {
  playlistData: PlaylistData | undefined;
  isLoading: boolean;
};

type Props = DispatchProps & OwnProps & StateProps;

export class Results extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getItem('sessions/get-playlist', this.props.sessionId);
  }

  public render() {
    const { playlistData, isLoading } = this.props;

    return (
      <Container className="results-page">
        <div className="user-page-title">
          <h2 className="font-color-base">
            Thank you - your playlist is below - you can save it for listening
            on Spotify or YouTube!
          </h2>
        </div>
        <div>
          <p className="spaced-text">
            We hope you or your loved one enjoy the music included in this
            playlist. And remember, this need not be the final playlist. It can
            be a starting point for a journey to personalising this music
            collection! You can easily adapt the playlist on YouTube or Spotify
            by removing tracks that don't appear to evoke positive feelings or
            memories and adding others that you think may be missing. You can
            find guidance on how to do that in the email we sent you. Happy
            listening!
          </p>
        </div>
        {isLoading && <Loading />}
        {!isLoading && !playlistData && <Loading />}
        {!isLoading && playlistData && !playlistData.playlist.length && (
          <p>No songs matched the details you entered.</p>
        )}
        {!isLoading && playlistData && !!playlistData.playlist.length && (
          <div>
            <Row>
              <Column md={12} className="blue-box">
                <Column md={6}>
                  <MusicServiceTile
                    titleName="Spotify"
                    descriptionName="Spotify"
                    icon={{
                      faIcon: faSpotify,
                      classNames: 'spotify-icon',
                    }}
                    link={`/api/playlists/spotify/initiate-authorization/?session=${this.props.sessionId}`}
                  />
                </Column>
                {FEATURE_FLAGS['hide_youtube'] !== 'True' && (
                  <Column md={6}>
                    <MusicServiceTile
                      titleName="YouTube"
                      descriptionName="YouTube"
                      image={{
                        imageSrc: '/static/images/youtube-icon.png',
                        classNames: 'youtube-icon-image',
                      }}
                      link={`/api/playlists/youtube/initiate-authorization/?session=${this.props.sessionId}`}
                    />
                  </Column>
                )}
              </Column>
            </Row>
            <Column md={6}>
              <PlaylistName
                playlistName={playlistData.playlist_name}
                sessionId={this.props.sessionId}
              />
              <h2 className="font-color-base">
                Preview snippets of songs below
              </h2>
              <MusicPlayer playlist={playlistData.playlist} />
              <ul className="results-screen-song-listing padding-left-none font-size-large">
                {playlistData.playlist.map((song, index) => (
                  <ResultsScreenSong
                    key={index}
                    song={song}
                    songNumber={index}
                    removeSong={this.removeSong}
                  />
                ))}
              </ul>
            </Column>
            <Column md={1} />
            <Column md={5}>
              <div className="help">
                <FontAwesomeIcon icon={faEnvelope} className="envelope-icon" />
                <i className="fa-regular fa-envelope" />
                <p>
                  If you have any problems, please contact us at:{' '}
                  <a href="mailto:info@musicformymind.com">
                    info@musicformymind.com
                  </a>
                </p>
              </div>
            </Column>
          </div>
        )}
      </Container>
    );
  }

  public removeSong = (song: string) => {
    this.props.updatePlaylistAndReloadPage(this.props.sessionId, song);
    setTimeout(
      () => this.props.getItem('sessions/get-playlist', this.props.sessionId),
      500
    );
  };
}

const mapStateToProps = ({ responses, items }: StoreState): StateProps => {
  return {
    playlistData: getItemByName(items, 'sessions/get-playlist') as
      | PlaylistData
      | undefined,
    isLoading: isPending(responses, GET_ITEM, 'sessions/get-playlist'),
  };
};

export default connect(mapStateToProps, {
  getItem,
  updatePlaylistAndReloadPage,
})(Results);
