import React from 'react';

interface Props {
  field: any; // eslint-disable-line
}

class ObservationField extends React.PureComponent<Props> {
  private onClickWrapper = () => {
    this.props.field.input.onChange(this.props.field.input.value);
  };

  public render() {
    const field = this.props.field;
    return (
      <span onClick={this.onClickWrapper} className="margin-horizontal-small">
        <label className={field.input.checked ? 'label-active' : ''}>
          <input type="radio" {...field.input} />
          {field.input.value}
        </label>
      </span>
    );
  }
}

const renderObservationRadioField = (field: unknown) => {
  return <ObservationField field={field} />;
};

export default renderObservationRadioField;
