import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { focusFieldOnMountRef } from '^/utils/forms';
import { ResponseErrors } from '^/common/error-handling/types';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';

interface FormData {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
}

interface ExternalProps {
  isLoading?: boolean;
  errors?: ResponseErrors;
}

type OwnProps = InjectedFormProps<FormData, ExternalProps>;

type Props = OwnProps & ExternalProps;

const ResetPasswordForm: React.FunctionComponent<Props> = props => (
  // eslint-disable-next-line react/jsx-handler-names
  <form onSubmit={props.handleSubmit}>
    <FormGroup block>
      <label htmlFor="password">New password</label>
      <Field
        ref={focusFieldOnMountRef}
        id="password"
        component="input"
        name="new_password1"
        type="password"
        disabled={props.isLoading}
      />
      <FormErrorRenderer
        formErrors={props.errors}
        formErrorsKey="new_password1"
      />
    </FormGroup>
    <FormGroup block>
      <label htmlFor="confirm-password">Confirm password</label>
      <Field
        id="confirm-password"
        component="input"
        name="new_password2"
        type="password"
        disabled={props.isLoading}
      />
      <FormErrorRenderer
        formErrors={props.errors}
        formErrorsKey="new_password2"
      />
    </FormGroup>
    <FormErrorRenderer
      formErrors={props.errors}
      formErrorsKey="non_field_errors"
    />
    <Button block className="margin-vertical-base" type="submit">
      Reset password
    </Button>
  </form>
);

export default reduxForm<FormData, ExternalProps>({
  form: 'reset-password',
  destroyOnUnmount: false,
})(ResetPasswordForm);
