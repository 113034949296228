import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Observation from './observation';
import { ObservationType } from './observation/types';

interface OwnProps {
  observationType: ObservationType;
}

type RouteProps = RouteComponentProps<{ uid: string }>;

type Props = RouteProps & OwnProps;

export type ListeningSessionProps = Props;

export class ListeningSession extends React.PureComponent<Props> {
  public render() {
    return (
      <div>
        <h1>Listening session</h1>
        <Observation
          observationType={this.props.observationType}
          sessionId={this.props.match.params.uid}
        />
      </div>
    );
  }
}

export default ListeningSession;
