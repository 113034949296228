import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import {
  ConsulteeConsentFormData,
  ConsulteeConsentFormResponseData,
} from '^/app/authenticated/consultee-consent-form/types';
import { ThunkDispatch } from '^/store/types';
import { showFormErrorToast } from '^/common/error-handling/utils';

export const SUBMIT_CONSULTEE_CONSENT_FORM_AND_REDIRECT_TO_SESSION_OVERVIEW = makeAsyncActionSet(
  'SUBMIT_CONSULTEE_CONSENT_FORM_AND_REDIRECT_TO_SESSION_OVERVIEW'
);
export const submitConsulteeConsentFormAndRedirectToSessionOverview = (
  researchSessionId: string,
  data: ConsulteeConsentFormData
) => {
  return (dispatch: ThunkDispatch) => {
    return request<ConsulteeConsentFormResponseData>(
      SUBMIT_CONSULTEE_CONSENT_FORM_AND_REDIRECT_TO_SESSION_OVERVIEW,
      `/api/research-sessions/consultee-consent/${researchSessionId}/`,
      'POST',
      data,
      {
        shouldRethrow: () => true,
      }
    )(dispatch)
      .then(response => {
        if (response) {
          dispatch(push(`/research/${researchSessionId}`));
          toast.success('Consultee consent form submitted');
        }
      })
      .catch(() => {
        showFormErrorToast();
      });
  };
};
