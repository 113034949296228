import React from 'react';

import { Song } from '^/common/types';

interface Props {
  song: Song;
  songNumber: number;
  removeSong: (song: string) => void;
}

class ResultsScreenSong extends React.PureComponent<Props> {
  public render() {
    const { song, songNumber } = this.props;
    return (
      <li>
        {songNumber + 1}. {song.artist.toLowerCase()} –{' '}
        {song.title.toLowerCase()}{' '}
        <a className="remove-song" onClick={this.onRemoveSong}>
          Remove
        </a>
      </li>
    );
  }

  public onRemoveSong = () => {
    this.props.removeSong(this.props.song.spotify_uri);
  };
}

export default ResultsScreenSong;
