import { FormGroup, SpacedGroup } from '@dabapps/roe';
import React from 'react';

import AppButton from './app-button';
import UserPageTitle from './user-page-title';

const ExampleUserAppPage = React.memo(() => (
  <>
    <UserPageTitle>
      Music Questionnaire for people living with memory loss or dementia
    </UserPageTitle>
    <p>
      In order to create a personal playlist for yourself or your loved one
      living with memory loss or dementia, we would like to ask you some
      questions.
    </p>
    <p>
      There are no right or wrong answers, just answer to the best of your
      abilities. Please note that some of the questions may refer to the period
      when you or your loved one were between the ages of 13 and 19.
    </p>
    <h3>Background information (page 1 of 4)</h3>
    <p>
      <strong>
        The following questions refer to the music listener's background.
      </strong>
    </p>
    <hr />
    <FormGroup block>
      <label htmlFor="textbox">
        <strong>Relative/Friend Name</strong>
      </label>
      <input id="textbox" placeholder="Enter first and last name" />
    </FormGroup>
    <FormGroup>
      <input type="checkbox" id="check" />
      <label htmlFor="check">
        I, the Consultee, have been consulted about this Resident's
        participation in this research project. I have had the opportunity to
        ask questions about the study and understand what is involved.
      </label>
    </FormGroup>
    <SpacedGroup block>
      <AppButton className="secondary">Next...</AppButton>
      <AppButton className="transparent">Cancel</AppButton>
    </SpacedGroup>
  </>
));

export default ExampleUserAppPage;
