import { ResearchSessionOverviewData } from '^/app/authenticated/session-overview/types';

export const canAccessConsulteeConsentForm = (
  sessionStatus: ResearchSessionOverviewData
) => {
  return !sessionStatus.is_consultee_consent_form_completed;
};

export const canAccessResidentConsentForm = (
  sessionStatus: ResearchSessionOverviewData
) => {
  return !sessionStatus.is_resident_consent_form_completed;
};

export const canAccessMusicQuestionnaire = (
  sessionStatus: ResearchSessionOverviewData
) => {
  return (
    sessionStatus.is_consultee_consent_form_completed ||
    sessionStatus.is_resident_consent_form_completed
  );
};

export const canRunSession = (sessionStatus: ResearchSessionOverviewData) => {
  return (
    sessionStatus.is_music_questionnaire_completed &&
    sessionStatus.is_initial_playlist_complete
  );
};

export const canUploadVideoFeedback = (
  sessionStatus: ResearchSessionOverviewData
) => {
  return sessionStatus.is_music_questionnaire_completed;
};

export const canViewPlaylist = (sessionStatus: ResearchSessionOverviewData) => {
  return sessionStatus.is_listening_session_completed;
};
