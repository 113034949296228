import { Column, ContentBox, Row, Container } from '@dabapps/roe';
import React from 'react';

const AuthWrapper: React.FunctionComponent = React.memo(props => (
  <Container>
    <Row>
      <Column sm={10} smOffset={1} md={6} mdOffset={3} lg={4} lgOffset={4}>
        <ContentBox>{props.children}</ContentBox>
      </Column>
    </Row>
  </Container>
));

export default AuthWrapper;
