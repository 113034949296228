import React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import { forgotPassword } from '^/app/unauthenticated/auth/actions';
import ForgotPasswordForm from '^/app/unauthenticated/auth/components/forgot-password/form';
import AuthWrapper from '^/app/unauthenticated/auth/components/wrapper';
import PageHeader from '^/common/components/page-header';

type DispatchProps = ResolveThunks<{
  onSubmitForgotPasswordForm: typeof forgotPassword;
}>;

type Props = DispatchProps;

const ForgotPassword: React.FunctionComponent<Props> = props => (
  <AuthWrapper>
    <PageHeader>Forgot password</PageHeader>
    <ForgotPasswordForm onSubmit={props.onSubmitForgotPasswordForm} />
  </AuthWrapper>
);

export default connect(undefined, {
  onSubmitForgotPasswordForm: forgotPassword,
})(ForgotPassword);
