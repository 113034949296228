import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import AppButton from '^/common/components/app-button';

interface Props {
  titleName: string;
  descriptionName: string;
  icon?: {
    faIcon: IconProp;
    classNames: string;
  };
  link: string;
  image?: {
    imageSrc: string;
    classNames: string;
  };
}

class MusicServiceTile extends React.PureComponent<Props> {
  public render() {
    const { titleName, descriptionName, icon, link, image } = this.props;

    return (
      <div className="external-service-tile">
        <h1 className="title">Listen to the full playlist on {titleName}</h1>
        <p className="subtitle">
          <b>If you have a {descriptionName} account:</b>
          <br />
          We'll add this playlist to your existing account.
        </p>
        <a href={link} target="_blank">
          <AppButton className="playlist-button">
            {icon ? (
              <FontAwesomeIcon icon={icon.faIcon} className={icon.classNames} />
            ) : (
              undefined
            )}
            {image ? (
              <img src={image.imageSrc} alt="" className={image.classNames} />
            ) : (
              undefined
            )}
            <span className="link">create my playlist</span>
          </AppButton>
        </a>
      </div>
    );
  }
}

export default MusicServiceTile;
