import { IItemConfig } from '@dabapps/chadmin';

export const addEditSessionFormConfig: IItemConfig = {
  resident_reference: {
    label: 'Resident reference',
    max_length: 255,
    type: 'text',
    columnProps: { xs: 12 },
    placeholder:
      '3 letters of surname, two letters of first, two letters of location and ID number',
  },

  location: {
    label: 'Location',
    max_length: 255,
    type: 'text',
    columnProps: { md: 6 },
    placeholder: 'Care home / location',
  },

  session_date: {
    label: 'Session date (optional)',
    type: 'date',
    columnProps: { md: 6 },
  },
};
