import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { connect, ResolveThunks } from 'react-redux';

import { getCurrentPageForPublicSession } from '^/app/unauthenticated/user/questionnaire/actions';
import { PLAYLIST_POLL_INTERVAL_MS } from './constants';

interface OwnProps {
  sessionId: string;
}

type DispatchProps = ResolveThunks<{
  getCurrentPageForPublicSession: typeof getCurrentPageForPublicSession;
}>;

type Props = DispatchProps & OwnProps;

export class GeneratingPlaylist extends React.PureComponent<Props> {
  public componentDidMount() {
    window.setTimeout(
      () => this.props.getCurrentPageForPublicSession(this.props.sessionId),
      PLAYLIST_POLL_INTERVAL_MS
    );
  }

  public render() {
    return (
      <div className="text-align-center generating-playlist-wrapper">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          className="primary margin-vertical-large icon-x-large"
        />
        <h2 className="font-color-base">Generating Playlist&hellip;</h2>
      </div>
    );
  }
}

export default connect(undefined, {
  getCurrentPageForPublicSession,
})(GeneratingPlaylist);
