import React from 'react';
import { Row, Column } from '@dabapps/roe';

import { ObservationData } from '^/app/authenticated/listening-session/observation/types';
import { OBSERVATION_TYPES } from '^/app/authenticated/listening-session/observation/const';

interface OwnProps {
  observationData: ObservationData | null;
}

const ReadonlyObservation = (props: OwnProps) => {
  return (
    <>
      {Object.entries(OBSERVATION_TYPES).map(
        ([observationType, observationTypeDisplayName], index) => (
          <Row
            key={`row_${observationType}`}
            className={index % 2 !== 0 ? 'bg-light-gray' : ''}
          >
            <Column md={4} className="text-align-right">
              <p>{observationTypeDisplayName}</p>
            </Column>
            <Column md={8}>
              <p>
                <strong>
                  {(props.observationData &&
                    props.observationData[
                      observationType as keyof ObservationData
                    ]) ||
                    0}
                </strong>
              </p>
            </Column>
          </Row>
        )
      )}
    </>
  );
};

export default ReadonlyObservation;
