import { Collections } from '@dabapps/redux-api-collections';

import { Items, itemToRecordMapping } from '^/common/items';
import { UpdateResearchSessionData } from '^/app/authenticated/session-management/types';

export interface Collections {
  'research-sessions': UpdateResearchSessionData;
}

const collectionToRecordMapping = {
  'research-sessions': UpdateResearchSessionData,
};

export const collections = Collections<Collections, Items>(
  collectionToRecordMapping,
  itemToRecordMapping
);
