import { hasFailed, isPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'underscore';

import { StoreState } from '^/store/types';
import { getResponseData } from '^/common/utils';
import { getObservationPlaylist, GET_OBSERVATION_PLAYLIST } from './actions';
import { ObservationPlaylistData, ObservationScoreKey } from './types';
import Loading from '^/common/components/loading';
import GenericError from '^/common/components/generic-error';
import {
  MAX_NUMBER_OF_OBSERVERS,
  MAX_SCORE_PER_OBSERVATION_CRITERIA,
  ObservationScoreColourGrade,
} from './const';
import { OBSERVATION_TYPES } from '^/app/authenticated/listening-session/observation/const';
import AdminPageTitle from '^/common/components/admin-page-title';
import MusicPlayer from '^/common/components/music-player';

export type RouteParamProps = RouteComponentProps<{
  uid: string;
}>;

type DispatchProps = ResolveThunks<{
  getObservationPlaylist: typeof getObservationPlaylist;
}>;

interface StateProps {
  playlist: ObservationPlaylistData;
  playlistLoading: boolean;
  playlistLoadingFailed: boolean;
}

type Props = RouteParamProps & StateProps & DispatchProps;

export class ObservationPlaylist extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getObservationPlaylist(this.props.match.params.uid);
  }

  private getScoreColourGradeHex(score: number, maxScorePerSection: number) {
    const scoreAsPercentDecimal = score / maxScorePerSection;
    if (scoreAsPercentDecimal < 0.25) return ObservationScoreColourGrade.RED;
    if (scoreAsPercentDecimal < 0.5) return ObservationScoreColourGrade.YELLOW;
    if (scoreAsPercentDecimal < 0.75)
      return ObservationScoreColourGrade.LIGHT_GREEN;
    return ObservationScoreColourGrade.DARK_GREEN;
  }

  public render() {
    if (this.props.playlistLoading) return <Loading />;
    if (this.props.playlistLoadingFailed || !this.props.playlist)
      return <GenericError />;

    const maxScorePerSection =
      MAX_SCORE_PER_OBSERVATION_CRITERIA *
      this.props.playlist.playlist.length *
      MAX_NUMBER_OF_OBSERVERS;
    const maxScoreOverall =
      maxScorePerSection * Object.keys(this.props.playlist.scores).length;
    const overallScore = _.reduce(
      this.props.playlist.scores,
      (memo, score) => memo + score,
      0
    );

    return (
      <div>
        <AdminPageTitle researchSessionId={this.props.match.params.uid}>
          Playlist and results
        </AdminPageTitle>
        <Row>
          <Column md={8}>
            <Row className="margin-top-large display-none lg-display-block">
              <Column md={8}>
                <strong>Personalised Playlist</strong>
              </Column>
              <Column md={4}>
                <strong>Results</strong>
              </Column>
            </Row>
            <hr className="display-none lg-display-block" />
            <Row>
              <Column lg={8}>
                <MusicPlayer playlist={this.props.playlist.playlist} />
                <ul className="observation-results-playlist padding-left-none">
                  {this.props.playlist &&
                    this.props.playlist.playlist.map((song, index) => (
                      <li key={`${song}-${index}`}>
                        <span className="margin-right-large">{index + 1}.</span>
                        {song.artist} - {song.title}
                      </li>
                    ))}
                </ul>
              </Column>
              <Column lg={4}>
                {(Object.keys(
                  this.props.playlist.scores
                ) as readonly ObservationScoreKey[]).map(scoreKey => (
                  <Row key={scoreKey}>
                    <Column md={8}>
                      {scoreKey in OBSERVATION_TYPES &&
                        OBSERVATION_TYPES[scoreKey]}
                    </Column>
                    <Column md={4}>
                      <div className="text-align-right margin-bottom-small">
                        <span
                          style={{
                            backgroundColor: this.getScoreColourGradeHex(
                              this.props.playlist.scores[scoreKey],
                              maxScorePerSection
                            ),
                          }}
                        >
                          {this.props.playlist.scores[scoreKey]}/
                          {maxScorePerSection}
                        </span>
                      </div>
                    </Column>
                  </Row>
                ))}
                <Row key="results-row">
                  <Column md={8}>
                    <strong>Results</strong>
                  </Column>
                  <Column md={4}>
                    <div className="text-align-right margin-bottom-small">
                      <span
                        style={{
                          backgroundColor: this.getScoreColourGradeHex(
                            overallScore,
                            maxScoreOverall
                          ),
                        }}
                      >
                        {overallScore}/{maxScoreOverall}
                      </span>
                    </div>
                  </Column>
                </Row>
                <Row>
                  <div>
                    <a
                      target="_blank"
                      download="playlist.csv"
                      href={`/api/research-sessions/${this.props.match.params.uid}/playlist-download`}
                    >
                      Export playlist CSV data
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      download="reaction.csv"
                      href={`/api/research-sessions/${this.props.match.params.uid}/reaction-download`}
                    >
                      Export reactions CSV data
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      download={`${this.props.match.params.uid}-video.csv`}
                      href={`/api/research-sessions/${this.props.match.params.uid}/video-csv-download/`}
                    >
                      Download video CSV
                    </a>
                  </div>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    playlist: getResponseData(state.responses, GET_OBSERVATION_PLAYLIST)?.data,
    playlistLoading: isPending(state.responses, GET_OBSERVATION_PLAYLIST),
    playlistLoadingFailed: hasFailed(state.responses, GET_OBSERVATION_PLAYLIST),
  };
}

export default connect(mapStateToProps, {
  getObservationPlaylist,
})(ObservationPlaylist);
