export const MAX_NUMBER_OF_OBSERVERS = 2;
export const MAX_SCORE_PER_OBSERVATION_CRITERIA = 2;

export interface ObservationDisplayName {
  smiling: string;
  singing: string;
  hands: string;
  feet: string;
  head_or_neck: string;
  relative_influence: string;
  meaningful: string;
}

export const ObservationDisplayName: ObservationDisplayName = {
  smiling: 'Smiling',
  singing: 'Singing',
  hands: 'Hands',
  feet: 'Feet',
  head_or_neck: 'Head or Neck',
  relative_influence: 'Relative Influence',
  meaningful: 'Meaningful',
};

export const ObservationScoreColourGrade = {
  DARK_GREEN: '#b8e986',
  LIGHT_GREEN: '#cbe8ae',
  YELLOW: '#e8e8ae',
  RED: '#e8b3ae',
};
