import {
  ResponsesReducerState,
  AsyncActionSet,
  getErrorData,
} from '@dabapps/redux-requests';
import { toast } from 'react-toastify';
import { FormErrors } from '@dabapps/chadmin';
import { AxiosError } from 'axios';

import {
  ResponseErrors,
  ResponseErrorList,
  ResponseError,
} from '^/common/error-handling/types';

export function getResponseErrors(
  state: ResponsesReducerState,
  actionSet: AsyncActionSet,
  tag?: string
): ResponseErrors | undefined {
  const data = getErrorData(state, actionSet, tag);
  if (data && data.response) {
    return data.response.data;
  }
  return undefined;
}

export function isResponseErrorList(
  error: ResponseError
): error is ResponseErrorList {
  return Array.isArray(error);
}

export const showFormErrorToast = () => {
  toast.error('Please address errors before submitting');
};

export function getChadminErrorsFromAxiosError(
  error: AxiosError | null
): FormErrors | undefined {
  if (error && error.response) {
    return error.response.data;
  }
}

export function getResponseErrorsFromAxiosError(
  error: AxiosError | null
): ResponseErrors | undefined {
  if (error && error.response) {
    return error.response.data;
  }
}
