import { AdminList } from '@dabapps/chadmin';
import {
  getCollectionByName,
  GET_COLLECTION,
} from '@dabapps/redux-api-collections';
import { isPending } from '@dabapps/redux-requests';
import { Container, Column } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { sessionListingConfig } from '^/app/authenticated/session-listing/config';
import { ResearchSession } from '^/app/authenticated/session-listing/types';
import { collections } from '^/common/collections';
import AppButton from '^/common/components/app-button';
import Loading from '^/common/components/loading';
import PageHeader from '^/common/components/page-header';
import { StoreState } from '^/store/types';
import {
  RESEARCH_SESSIONS,
  PAGE_SIZE,
  DEFAULT_ORDERING,
} from '^/app/authenticated/session-listing/constants';
import { noop } from '^/common/utils';

const {
  actions: { getCollection },
} = collections;

interface StateProps {
  sessions: ReadonlyArray<ResearchSession>;
  sessionsCount: number;
  page: number;
  ordering: string | undefined;
  reverseOrdering: boolean | undefined;
  isLoading: boolean;
}

interface DispatchProps {
  getCollection: typeof getCollection;
}

type Props = StateProps & DispatchProps;

export class SessionListing extends React.Component<Props> {
  public componentDidMount() {
    this.props.getCollection(
      RESEARCH_SESSIONS,
      {
        pageSize: PAGE_SIZE,
        ordering: DEFAULT_ORDERING,
        reverseOrdering: true,
      },
      RESEARCH_SESSIONS
    );
  }

  public render() {
    const { sessions, sessionsCount, page, isLoading } = this.props;

    return (
      <Container className="session-listing">
        <PageHeader>
          <Column md={6}>Sessions</Column>
          <Column md={6}>
            <Link
              to="/research/add-session"
              className="float-right font-size-base"
            >
              <AppButton className="add-session-button">New session</AppButton>
            </Link>
          </Column>
        </PageHeader>
        <Column className="margin-top-large">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <AdminList
                items={sessions}
                columns={sessionListingConfig}
                deleteItem={noop}
                sortItems={this.sortItems}
                sortBy={this.props.ordering}
                sortByReversed={this.props.reverseOrdering}
                listName={'SESSIONS'}
                itemCount={sessionsCount}
                page={page}
                pageSize={PAGE_SIZE}
                changePage={this.changePage}
              />
              <a href="/api/exports/public-user-sessions/">
                <AppButton className="primary">
                  Export user app registrants
                </AppButton>
              </a>
            </>
          )}
        </Column>
      </Container>
    );
  }

  private sortItems = (sortPath: string, shouldReverse: boolean) => {
    this.props.getCollection(
      RESEARCH_SESSIONS,
      {
        pageSize: PAGE_SIZE,
        ordering: sortPath,
        reverseOrdering: shouldReverse,
        page: this.props.page,
      },
      RESEARCH_SESSIONS
    );
  };

  private changePage = (pageNumber: number) => {
    this.props.getCollection(
      RESEARCH_SESSIONS,
      {
        pageSize: PAGE_SIZE,
        ordering: this.props.ordering,
        reverseOrdering: this.props.reverseOrdering,
        page: pageNumber,
      },
      RESEARCH_SESSIONS
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => {
  const sessionsCollection = getCollectionByName(
    state.collections,
    RESEARCH_SESSIONS,
    RESEARCH_SESSIONS
  );
  return {
    sessions: sessionsCollection.results as ReadonlyArray<ResearchSession>,
    sessionsCount: sessionsCollection.count,
    page: sessionsCollection.page,
    ordering: sessionsCollection.ordering,
    reverseOrdering: sessionsCollection.reverseOrdering,
    isLoading: isPending(state.responses, GET_COLLECTION, RESEARCH_SESSIONS),
  };
};

export default connect(mapStateToProps, { getCollection })(SessionListing);
