import { ObservationData } from './types';

export const OBSERVATION_TYPES = {
  smiling: 'Smiling',
  singing: 'Singing',
  hands: 'Hands',
  feet: 'Feet',
  head_or_neck: 'Head or Neck',
  relative_influence: 'Relative Influence',
  meaningful: 'Meaningful',
};

export const OBSERVATION_VALUES = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
};

export const OBSERVATION_FORM_INITIAL_VALUES: Partial<ObservationData> = {
  smiling: 0,
  singing: 0,
  hands: 0,
  feet: 0,
  head_or_neck: 0,
  relative_influence: 0,
  meaningful: 0,
};
