import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ModalRenderer } from '@dabapps/roe';

import ResearchApp from '^/app/authenticated';
import { StoreState } from '^/store/types';
import UserApp from '^/app/unauthenticated';

type StateProps = Pick<StoreState, 'currentUser'> & Pick<StoreState, 'modals'>;

type RouteProps = RouteComponentProps<{}>;

type Props = RouteProps & StateProps;

const App: React.FunctionComponent<Props> = React.memo((props: Props) => (
  <>
    <Helmet>
      <title>Music for my Mind</title>
    </Helmet>
    <ModalRenderer modals={props.modals} />
    <Switch>
      <Route path="/research" component={ResearchApp} />
      <Route component={UserApp} />
    </Switch>
    <ToastContainer />
  </>
));

const mapStateToProps = ({ currentUser, modals }: StoreState): StateProps => {
  return {
    currentUser,
    modals,
  };
};

export default withRouter(connect(mapStateToProps)(App));
