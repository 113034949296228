import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { Container, Column } from '@dabapps/roe';
import { isPending, getErrorData } from '@dabapps/redux-requests';
import { getItemByName, GET_ITEM } from '@dabapps/redux-api-collections';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ResearchSessionOverviewData } from '^/app/authenticated/session-overview/types';
import { collections } from '^/common/collections';
import { StoreState } from '^/store/types';
import AdminPageTitle from '^/common/components/admin-page-title';
import Loading from '^/common/components/loading';
import { consulteeConsentFormConfig } from '^/app/authenticated/consultee-consent-form/config';
import { noop } from '^/common/utils';
import {
  submitConsulteeConsentFormAndRedirectToSessionOverview,
  SUBMIT_CONSULTEE_CONSENT_FORM_AND_REDIRECT_TO_SESSION_OVERVIEW,
} from '^/app/authenticated/consultee-consent-form/actions';
import { ConsulteeConsentFormData } from '^/app/authenticated/consultee-consent-form/types';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';

const {
  actions: { getItem, clearItem },
} = collections;

export type RouteParamProps = RouteComponentProps<{
  uid: string;
}>;

type DispatchProps = {
  getItem: typeof getItem;
  clearItem: typeof clearItem;
};

type StateProps = {
  sessionData: ResearchSessionOverviewData | undefined;
  formData: ConsulteeConsentFormData | undefined;
  isLoading: boolean;
  errors: FormErrors | undefined;
};

export type Props = RouteParamProps &
  DispatchProps &
  StateProps &
  ResolveThunks<{
    submitConsulteeConsentFormAndRedirectToSessionOverview: typeof submitConsulteeConsentFormAndRedirectToSessionOverview;
  }>;

export class ConsulteeConsentForm extends React.PureComponent<Props> {
  public componentDidMount() {
    const researchSessionUid = this.props.match.params.uid;
    this.props.getItem('research-sessions/overview', researchSessionUid);
    this.props.getItem(
      'research-sessions/consultee-consent',
      researchSessionUid
    );
  }

  public componentWillUnmount() {
    this.props.clearItem('research-sessions/consultee-consent');
  }

  public render() {
    const { isLoading, sessionData, formData } = this.props;

    return (
      <Container>
        {isLoading || !sessionData ? (
          <Loading />
        ) : (
          <>
            <AdminPageTitle researchSessionId={sessionData.id}>
              Consultee consent form for {sessionData.resident_reference} -{' '}
              {sessionData.session_date}
            </AdminPageTitle>
            <Column xs={12} sm={6}>
              {formData ? (
                <AdminEditCreate
                  item={formData}
                  fields={Object.keys(consulteeConsentFormConfig)}
                  formName="CONSULTEE_CONSENT_FORM"
                  itemOptions={consulteeConsentFormConfig}
                  setPendingUploadInForm={noop}
                  readOnly
                />
              ) : (
                <AdminEditCreate
                  fields={Object.keys(consulteeConsentFormConfig)}
                  formName="CONSULTEE_CONSENT_FORM"
                  itemOptions={consulteeConsentFormConfig}
                  setPendingUploadInForm={noop}
                  saveButtonText="Submit Consent"
                  saveButtonClassName="primary margin-vertical-base"
                  additionalFormControls={
                    <a
                      className="margin-left-large text-decoration-none uppercase font-weight-normal primary"
                      href={`/research/${sessionData.id}`}
                    >
                      Cancel
                    </a>
                  }
                  createItem={this.formSubmit}
                  itemErrors={this.props.errors}
                  loading={isLoading}
                />
              )}
            </Column>
          </>
        )}
      </Container>
    );
  }

  private formSubmit = (formData: ConsulteeConsentFormData) => {
    this.props.submitConsulteeConsentFormAndRedirectToSessionOverview(
      this.props.match.params.uid,
      formData
    );
  };
}

const mapStateToProps = ({ responses, items }: StoreState): StateProps => {
  return {
    sessionData: getItemByName(items, 'research-sessions/overview') as
      | ResearchSessionOverviewData
      | undefined,
    formData: getItemByName(items, 'research-sessions/consultee-consent') as
      | ConsulteeConsentFormData
      | undefined,
    isLoading:
      isPending(responses, GET_ITEM, 'research-sessions/overview') ||
      isPending(responses, GET_ITEM, 'research-sessions/consultee-consent') ||
      isPending(
        responses,
        SUBMIT_CONSULTEE_CONSENT_FORM_AND_REDIRECT_TO_SESSION_OVERVIEW
      ),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(
        responses,
        SUBMIT_CONSULTEE_CONSENT_FORM_AND_REDIRECT_TO_SESSION_OVERVIEW
      )
    ),
  };
};

export default connect(mapStateToProps, {
  clearItem,
  getItem,
  submitConsulteeConsentFormAndRedirectToSessionOverview,
})(ConsulteeConsentForm);
