import { IItemConfig } from '@dabapps/chadmin';
import { LabelPosition } from '@dabapps/chadmin/dist/edit-create/dynamic-forms/field-types/types';

export const residentConsentFormConfig: IItemConfig = {
  resident_consent: {
    type: 'static',
    text: 'Resident Consent',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'font-size-h3 font-color-h3 bold',
  },
  resident_name: {
    label: 'Resident Name',
    type: 'text',
    placeholder: 'enter first and last name',
    max_length: 255,
    columnProps: { xs: 8 },
    fieldGroupClassName: 'bold-label',
  },
  resident_information_sheet: {
    type: 'static',
    text: 'Information sheet',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'bold',
  },
  resident_has_confirmed_information_sheet: {
    type: 'boolean',
    label:
      'I confirm that I have read (or have had read to me) and understood the participant information sheet for the above mentioned Music for my Mind project, and where possible consulted with my family members. I have had the opportunity to ask questions about the study and they have been answered satisfactorily. ',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  resident_right_to_withdrawal: {
    type: 'static',
    text: 'Right to Withdrawal',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'bold',
  },
  resident_has_confirmed_right_to_withdrawal: {
    type: 'boolean',
    label:
      'I understand that my participation is voluntary and I am free to withdraw at any time without giving any reason and without my care or legal rights being affected.',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  resident_filming_research_permission: {
    type: 'static',
    text: 'Filming/research permission',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'bold',
  },
  resident_has_given_permission_for_filming: {
    type: 'boolean',
    label:
      'I understand and give permission for the music sessions to be filmed for research purpose. I agree to my personal details and video or audio footage being held by Music for my Mind, accessed by responsible individuals from the Music for my Mind team, used for the purpose of this project and understand they will be securely protected. ',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  resident_has_given_permission_for_data_to_be_retained: {
    type: 'boolean',
    label:
      'Should my participation in the study end because I withdraw or no longer have capacity to consent to my continued participation, I understand and give permission for data, already collected during my participation to be retained and used for the purposes of this study.',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  resident_has_agreed_to_take_part_in_study: {
    type: 'boolean',
    label: 'I agree to take part in this study',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
    fieldWrapperClassName: 'form-section',
  },
  friend_relative_consent: {
    type: 'static',
    text: 'Friend/Relative Consent',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'font-size-h3 font-color-h3 bold',
  },
  friend_or_relative_name: {
    label: 'Relative/Friend Name',
    type: 'text',
    placeholder: 'enter first and last name',
    max_length: 255,
    columnProps: { xs: 8 },
    fieldGroupClassName: 'bold-label',
  },
  friend_or_relative_information_sheet: {
    type: 'static',
    text: 'Information sheet',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'bold',
  },
  friend_or_relative_has_confirmed_information_sheet: {
    type: 'boolean',
    label:
      'I confirm that I have read (or have had read to me) and understood the participant information sheet for the above mentioned Music for my Mind project. I have had the opportunity to ask questions about the study and they have been answered satisfactorily.',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  friend_or_relative_right_to_withdrawal: {
    type: 'static',
    text: 'Right to Withdrawal',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'bold',
  },
  friend_or_relative_has_confirmed_right_to_withdrawal: {
    type: 'boolean',
    label:
      'I understand that my participation is voluntary and I am free to withdraw at any time without giving any reason and without my loved one’s care or legal rights being affected. I understand that if I withdraw from the study, all data already collected will be retained for further use in the research.',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  friend_or_relative_filming_research_permission: {
    type: 'static',
    text: 'Focus group/filming/research permission',
    columnProps: { xs: 12 },
    fieldGroupClassName: 'bold',
  },
  friend_or_relative_has_given_permission_for_filming: {
    type: 'boolean',
    label:
      'I agree to participate in focus group discussions or one-to-one interviews, where applicable, and share my opinion and feedback on the effects the music has on my loved one and how the process could be improved. I give permission for these discussions to be audio recorded for further analysing by the research team.',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  friend_or_relative_has_given_permission_for_data_to_be_retained: {
    type: 'boolean',
    label:
      'I understand and give permission for the music sessions to be filmed for research purposes. I agree to my personal details and video or audio footage being held by Music for my Mind, accessed by responsible individuals from the Music for my Mind team, used for the purpose of this project and understand they will be securely protected.',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
  },
  friend_or_relative_has_agreed_to_take_part_in_study: {
    type: 'boolean',
    label: 'I agree to take part in this study',
    fieldGroupClassName: 'checkbox',
    labelPosition: LabelPosition.AFTER_INPUT,
    columnProps: { xs: 12 },
    fieldWrapperClassName: 'form-section',
  },
  witnessed_by: {
    label: 'Witnessed by',
    type: 'text',
    placeholder: 'enter first and last name',
    max_length: 255,
    columnProps: { xs: 8 },
    fieldGroupClassName: 'bold-label',
  },
  name_of_researcher: {
    label: 'Name of Researcher',
    type: 'text',
    placeholder: 'enter first and last name',
    max_length: 255,
    columnProps: { xs: 8 },
    fieldGroupClassName: 'bold-label',
  },
};
