import { UpdateResearchSessionData } from '^/app/authenticated/session-management/types';
import { ResidentConsentFormData } from '^/app/authenticated/resident-consent-form/types';
import { ConsulteeConsentFormData } from '^/app/authenticated/consultee-consent-form/types';
import { ResearchSessionOverviewData } from '^/app/authenticated/session-overview/types';
import { PlaylistData } from '^/common/types';

export interface Items {
  'research-sessions': UpdateResearchSessionData;
  'research-sessions/overview': ResearchSessionOverviewData;
  'research-sessions/resident-consent': ResidentConsentFormData;
  'research-sessions/consultee-consent': ConsulteeConsentFormData;
  'sessions/get-playlist': PlaylistData;
}

export const itemToRecordMapping = {
  'research-sessions': UpdateResearchSessionData,
  'research-sessions/overview': ResearchSessionOverviewData,
  'research-sessions/resident-consent': ResidentConsentFormData,
  'research-sessions/consultee-consent': ConsulteeConsentFormData,
  'sessions/get-playlist': PlaylistData,
};
