import React from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { anyPending, hasFailed } from '@dabapps/redux-requests';
import { RouteComponentProps } from 'react-router-dom';

import QuestionnaireWizard from '^/common/components/questionnaire/wizard';
import {
  GET_QUESTIONNAIRE_OPTIONS,
  getQuestionnaireOptions,
} from '^/common/components/questionnaire/musical-questionnaire/actions';
import {
  getCurrentPageForPublicSession,
  GET_CURRENT_PAGE_FOR_PUBLIC_SESSION,
} from '^/app/unauthenticated/user/questionnaire/actions';
import { StoreState } from '^/store/types';
import { PublicUserSessionPageName } from '^/common/components/questionnaire/wizard/types';
import GenericError from '^/common/components/generic-error';
import Loading from '^/common/components/loading';
import ContactDetails from '^/app/unauthenticated/user/questionnaire/contact-details';
import { PUBLIC_USER_QUESTIONNAIRE_ID } from '^/app/unauthenticated/user/questionnaire/const';
import BackgroundInformation from '^/common/components/questionnaire/musical-questionnaire/background-information';
import PersonalityAndBehaviour from '^/common/components/questionnaire/musical-questionnaire/personality-and-behaviour';
import MusicalPreferences from '^/common/components/questionnaire/musical-questionnaire/musical-preferences';
import Feedback from '^/common/components/questionnaire/musical-questionnaire/feedback';
import GeneratingPlaylist from './generating-playlist';
import Results from './results';

interface StateProps {
  sessionId: string;
  isLoading: boolean;
  hasFailed: boolean;
}

type DispatchProps = ResolveThunks<{
  getCurrentPageForPublicSession: typeof getCurrentPageForPublicSession;
  getQuestionnaireOptions: typeof getQuestionnaireOptions;
}>;

type RouteProps = RouteComponentProps<{ sessionId: string }>;

type Props = RouteProps & StateProps & DispatchProps;

export class PublicUserQuestionnaire extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getCurrentPageForPublicSession(
      this.props.match.params.sessionId
    );
    this.props.getQuestionnaireOptions();
  }

  public render() {
    if (this.props.hasFailed) {
      return <GenericError />;
    }

    if (this.props.isLoading) {
      return <Loading />;
    }

    return (
      <QuestionnaireWizard
        pages={[
          {
            name: PublicUserSessionPageName.NAME_AND_EMAIL,
            title: 'Name and email – we will send your playlist here',
            pageContent: <ContactDetails />,
          },
          {
            name: PublicUserSessionPageName.BACKGROUND_INFORMATION,
            title:
              'Music Questionnaire for people living with memory loss or dementia',
            pageContent: (
              <BackgroundInformation
                isResearchQuestionnaire={false}
                sessionId={this.props.match.params.sessionId}
              />
            ),
          },
          {
            name: PublicUserSessionPageName.PERSONALITY_AND_BEHAVIOUR,
            title:
              'Music Questionnaire for people living with memory loss or dementia',
            pageContent: (
              <PersonalityAndBehaviour
                isResearchQuestionnaire={false}
                sessionId={this.props.match.params.sessionId}
              />
            ),
          },
          {
            name: PublicUserSessionPageName.MUSICAL_PREFERENCES,
            title:
              'Music Questionnaire for people living with memory loss or dementia',
            pageContent: (
              <MusicalPreferences
                isResearchQuestionnaire={false}
                sessionId={this.props.match.params.sessionId}
              />
            ),
          },
          {
            name: PublicUserSessionPageName.FEEDBACK,
            title:
              'Music Questionnaire for people living with memory loss or dementia',
            pageContent: (
              <Feedback
                isResearchQuestionnaire={false}
                sessionId={this.props.match.params.sessionId}
              />
            ),
          },
          {
            name: PublicUserSessionPageName.GENERATING_PLAYLIST,
            pageContent: (
              <GeneratingPlaylist
                sessionId={this.props.match.params.sessionId}
              />
            ),
          },
          {
            name: PublicUserSessionPageName.RESULTS,
            pageContent: (
              <Results sessionId={this.props.match.params.sessionId} />
            ),
          },
        ]}
        questionnaireId={PUBLIC_USER_QUESTIONNAIRE_ID}
      />
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    isLoading: anyPending(state.responses, [
      GET_CURRENT_PAGE_FOR_PUBLIC_SESSION,
      GET_QUESTIONNAIRE_OPTIONS,
    ]),
    hasFailed:
      hasFailed(state.responses, GET_CURRENT_PAGE_FOR_PUBLIC_SESSION) ||
      hasFailed(state.responses, GET_QUESTIONNAIRE_OPTIONS),
  };
}

export default connect(mapStateToProps, {
  getCurrentPageForPublicSession,
  getQuestionnaireOptions,
})(PublicUserQuestionnaire);
