import { createSelector } from 'reselect';

import { StoreState } from '^/store/types';
import { ResearchSessionQuestionnaireState } from './types';

export const selectResearchQuestionnareState = (state: StoreState) =>
  state.researchQuestionnaire;

export const selectIsQuestionnaireReadonly = createSelector(
  selectResearchQuestionnareState,
  (researchQuestionnaire: ResearchSessionQuestionnaireState) =>
    researchQuestionnaire.is_complete
);

export const selectIsQuestionnairePageData = createSelector(
  selectResearchQuestionnareState,
  (researchQuestionnaire: ResearchSessionQuestionnaireState) =>
    researchQuestionnaire.pages
);
