import React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { FormGroup, Column, Row } from '@dabapps/roe';

import {
  OBSERVATION_TYPES,
  OBSERVATION_VALUES,
} from '^/app/authenticated/listening-session/observation/const';
import renderObservationRadioField from '^/app/authenticated/listening-session/observation/fields/observation-radio';

interface OwnProps {
  errors: Record<string, string> | null;
}

export class ObservationForm extends React.PureComponent<
  OwnProps & InjectedFormProps<{}, OwnProps>
> {
  private getErrorForField = (fieldName: string) => {
    if (this.props.errors) {
      return <span className="error">{this.props.errors[fieldName]}</span>;
    }

    return null;
  };

  public render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {Object.entries(OBSERVATION_TYPES).map(
          ([observationType, observationTypeDisplayName], index) => (
            <Row
              key={`row_${observationType}`}
              className={index % 2 !== 0 ? 'bg-light-gray' : ''}
            >
              <Column md={4} className="text-align-right">
                <p>{observationTypeDisplayName}</p>
              </Column>
              <Column md={8}>
                <FormGroup className="radio-buttons margin-vertical-none padding-vertical-large">
                  {Object.values(OBSERVATION_VALUES).map(observationValue => (
                    <span
                      key={`input_${observationType}_${observationValue}`}
                      className="margin-horizontal-small"
                    >
                      <Field
                        type="radio"
                        component={renderObservationRadioField}
                        name={observationType}
                        value={observationValue}
                      />
                    </span>
                  ))}
                  {this.getErrorForField(observationType)}
                </FormGroup>
              </Column>
            </Row>
          )
        )}
      </form>
    );
  }
}

export default reduxForm<{}, OwnProps>({
  form: 'observation',
})(ObservationForm);
