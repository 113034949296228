import { QuestionnaireData } from '^/common/components/questionnaire/musical-questionnaire/types';

export enum ResearchQuestionnairePageName {
  BACKGROUND_INFORMATION = 'BACKGROUND_INFORMATION',
  PERSONALITY_AND_BEHAVIOUR = 'PERSONALITY_AND_BEHAVIOUR',
  MUSICAL_PREFERENCES = 'MUSICAL_PREFERENCES',
  FEEDBACK = 'FEEDBACK',
}

export interface GetCurrentPageForResearchSession {
  current_page: string;
}

export interface GetResearchSessionQuestionnaireData {
  is_complete: boolean;
  pages: QuestionnaireData;
}

export type ResearchSessionQuestionnaireState = GetResearchSessionQuestionnaireData;
